<template>
    <div class="pageHeader">
        <div :class="['exam-title', {'design': isDesign}]">
            <el-row>
                <el-col :span="6" v-if="paperAB">
                    <el-row class="paper-ab">
                        <el-col :span="10" style="margin-top: 25px;">试卷类型</el-col>
                        <el-col :span="14" style="margin-top: 15px;">
                            <el-row >
                                <el-col :span="8">A</el-col>
                                <el-col :span="16" ref="paperA" style="margin-top: 4px;"><div class="block"></div></el-col>
                            </el-row>
                            <el-row style="margin-top: 10px;">
                                <el-col :span="8">B</el-col>
                                <el-col :span="16" ref="paperB" style="margin-top: 4px;"><div class="block"></div></el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="paperAB?18:24" class="header-title">
<!--                    <editor ref="editor" v-model="editorData" :init="editorInit" @onBlur="editorBlur"></editor>-->
                    <span v-html="examTitle" v-if="isPreview"></span>
                    <el-input v-model="examTitle" v-else type="textarea" :rows="2" resize="none" @blur="editorBlur"> </el-input>
                </el-col>
            </el-row>

        </div>
        <el-row ref="noRow">
            <el-col :span="10">
                <el-row class="exam-row">
                    <el-col :span="24">
                        <el-input v-model="row1" @blur="pageRowChange"> </el-input>
                    </el-col>
                </el-row>
                <el-row class="exam-row">
                    <el-col :span="24">
                        <el-input v-model="row2" @blur="pageRowChange"> </el-input>
                    </el-col>
                </el-row>
                <el-row class="notice-row">
                    <el-col :span="24" class="title"><span>注意事项</span></el-col>
                    <el-col :span="24" class="desc">
                        <span v-html="notice" v-if="isPreview"></span>
                        <el-input v-model="notice" v-else type="textarea" :autosize="{minRows:8, maxRows: 12}" resize="none" @blur="pageRowChange"> </el-input>
                    </el-col>
                </el-row>
                <el-row class="filling-row">
                    <el-col :span="8" style="text-align: right;"><span>正确填涂：</span></el-col>
                    <el-col :span="4">
                        <div class="rect-empty full"></div>
                    </el-col>
                    <el-col :span="6" style="text-align: right;"><span>缺 考：</span></el-col>
                    <el-col :span="4" >
                        <div class="rect-empty"></div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="13" :offset="1" style="padding: 20px 20px;">
                <regNo ref="regNo" :total="regNoTotal" :title="regNoTitle"></regNo>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    import regNo from "./components/regNo";
    import {mapActions, mapState} from "vuex";

    export default {
        name: "pageHeader",
        components:{
            regNo
        },
        props: {
            head:{
                type: Object
            },
            title: {
                type: String
            },
            regNoTitle: {
                type: String
            },
        },
        computed: {
            ...mapState({
                pageConfig: state => state.card.pageConfig,
                isPreview: state => state.card.isPreview,
            }),
        },
        watch: {
            title: {
                deep: true, immediate:true,
                handler(n, o){
                    if(n){
                        this.examTitle = n;
                    }
                }
            },
            head: {
                deep: true, immediate: true,
                handler(n, o) {
                    console.log("head", n);
                    if(n.row1 != null) this.row1 = n.row1;
                    if(n.row2 != null) this.row2 = n.row2;
                    if(n.notice != null) this.notice = n.notice;
                }
            },
            isPreview:{
                deep: true, immediate:true,
                handler(n, o){
                    this.isDesign = !n;
                }
            },
            pageConfig: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log("page header pageConfig", n);
                    if(this.regNoTotal != n.examNoTotal) this.regNoTotal = n.examNoTotal;
                    this.paperAB = n.paperAB;
                }
            }
        },
        data() {
            return {
                content: '', editor: null, editorData: '', isDesign: true, regNoTotal: 8, paperAB: false, examTitle: null,
                row1: '考场：_________   座位号：__________',
                row2: '姓名：_________   班  级：__________',
                notice: '1、答题前请考生先将自己的姓名、班级、考场座位填写清楚\n2、选择题部分请用2B铅笔填涂方框；非选择题部分请用0.5毫米黑色墨水签字笔书写\n3、请勿折叠，保持卡面清洁；请将所有答题内容写在此卷上，否则答题无效',
                editorInit: {
                    language_url: 'tinymce/langs/zh_CN.js',
                    language: 'zh_CN',
                    skin_url: '/tinymce/skins/ui/oxide',
                    plugins: 'link lists image code',
                    toolbar:
                        'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | undo redo',
                    branding: false,
                    menubar: false, inline: true,
                },
            }
        },
        mounted() {
            // tinymce.init({inline: true, menubar: false,});
            this.regNoTotal = this.pageConfig.examNoTotal;
        },
        methods: {
            ...mapActions('card',['tiAdd', 'setIsCanSave']),
            editorBlur(){
                // console.log("editor Blur", this.titleHeight);
                this.tiAdd({action: 'headerTitle', title: this.examTitle, text: this.examTitle});
            },
            getExamTitleHtml(){
                return this.examTitle.replaceAll("\n", "<br>");
            },
            pageRowChange(){
                this.tiAdd({action: 'headerRow', row1: this.row1, row2: this.row2, notice: this.notice,});
                this.setIsCanSave(true);
            },
            callJson(config, pageConfig, header){
                let {dpi} = config;
                let {pageWidth, pageHeight, pageLeft, pageTop, leftTop, ptWidth, ptHeight} = pageConfig;
                let {clientWidth, clientHeight, offsetLeft, offsetTop} = this.$refs.regNo.$el;
                let parent = this.$refs.noRow.$el;
                let myLeft = (parent.offsetLeft+offsetLeft)/dpi, myTop=(parent.offsetTop)/dpi, myWidth=clientWidth/dpi, myHeight=clientHeight/dpi;
                let regNoConfig = { regNoWidth: myWidth, regNoHeight: myHeight, regNoLeft: myLeft, regNoTop: myTop};
                let studentNo = this.$refs.regNo.callJson(config, pageConfig, header, regNoConfig);
                let pageHeader = {ticketNum: studentNo, paperName: this.examTitle,
                    row1: this.row1, row2: this.row2, notice: this.notice
                };
                if("paperA" in this.$refs){
                    let abList = [this.callABJson(config, pageConfig, "paperA", 0), this.callABJson(config, pageConfig, "paperB", 1)];
                    pageHeader["paperAB"] = abList;
                }
                console.log("pageHeader",pageHeader);
                return pageHeader;
            },
            callABJson(config, pageConfig, paperA, index){
                let {dpi} = config;
                let {pageLeft, pageTop, leftTop, ptWidth, ptHeight} = pageConfig;
                let {clientWidth, clientHeight, offsetLeft, offsetTop} = this.$refs[paperA].$el;
                let myLeft = offsetLeft/dpi, myTop=offsetTop/dpi, myWidth=clientWidth/dpi, myHeight=clientHeight/dpi;
                let position = { "top": myTop, "left": myLeft, "width": myWidth, "height": myHeight, left_begin: myLeft/ptWidth,
                    top_begin: myTop/ptWidth, left_end: (myLeft+myWidth)/ptWidth, top_end: (myTop+myHeight)/ptWidth,
                    width_scale: myWidth/ptWidth, height_scale: myHeight/ptHeight}
                return {position: position, index: index};

            }
        }
    }
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">

    .pageHeader{
        color: #333;
        height: 110mm;
        .exam-title {
            min-height: 76px;
        }
        .design {
            border: 1px solid #ced4da;
        }
        .paper-ab{
            justify-content: center;
            .block {
                width: 5mm;
                height: 2.5mm;
                border: 1px solid black;
            }
        }
        .header-title {
            line-height: 30px;
            height: 76px;
            span, ::v-deep .el-textarea__inner{
                font-size: 24px;
                padding: 0;
                text-align: center;
                font-weight: 700;
                overflow: hidden;
                color: black;
                border: none;
            }

        }
        .exam-row{
            line-height: 30px;
            ::v-deep .el-input__inner{
                font-size: 14px;
                padding: 0;
                //text-align: center;
                color: black;
                border: none;
            }
        }
        .notice-row{
            border: 1px dashed black;
            margin-top: 10px;
            .title{
                border-bottom: 1px dashed #000;
                span {
                    margin: 2mm 0;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 5mm;
                }
            }
            .desc {
                text-align: left;
                padding: 5px;
                word-break: break-all;
                ::v-deep .el-textarea__inner{
                    font-size: 14px;
                    padding: 0;
                    overflow-y: hidden;
                    //text-align: center;
                    color: black;
                    border: none;
                }
                span {
                    white-space: pre-line;
                }
                p {
                    font-size: 12px;
                    margin: 1mm 0;
                }
            }
        }
        .filling-row {
            border: 1px dashed black;
            margin-top: 10px;
            height: 36px;
            padding-top: 5px;
            .rect-empty {
                height: 2mm;
                margin-top: 5px;
                width: 5mm;
                border: 1px solid black;
            }
            .full {
                background-color: black;
            }
        }
    }
</style>
