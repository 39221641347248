<template>
    <div class="cardView" v-loading="loading">

        <paperSheet ref="paperSheet" :is-pre-view="false" :dbObject="dbObject" :inputPage="inputPage"></paperSheet>
        <div class="left-oper">

            <group-item title="布局、考号设置">
                <div slot="content">
                    <el-row>
                        <el-col :span="4">
                            <span style="font-size: 14px;font-weight: bold;">布局</span>
                        </el-col>
                        <el-col :span="14">
                            <el-radio-group v-model="leftForm.examType" @change="examTypeChange">
                                <el-radio :label="1">一栏</el-radio>
                                <el-radio :label="2">两栏</el-radio>
                            </el-radio-group>
                        </el-col>
                    </el-row>
<!--                    <el-row class="my-row">-->
<!--                        <el-col :span="24">-->
<!--                            <span style="font-size: 14px;">{{leftForm.layoutDesc}}</span>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
                    <el-row class="my-row">
                        <el-col :span="4">
                            <span style="font-size: 14px;font-weight: bold;">类型</span>
                        </el-col>
                        <el-col :span="14">
                            <el-radio-group v-model="leftForm.examNoType" @change="" style="margin-top: 3px;">
                                <el-radio :label="1">条形码</el-radio>
                                <el-radio :label="2">考号</el-radio>
                            </el-radio-group>
                        </el-col>
                        <el-col :span="6">
                            <el-select v-model="leftForm.examNoCount" filterable placeholder="请选择" style="width: 80px" @change="examNoTotalChange">
                                <el-option v-for="n of 10" :key="n+5" :label="n+5" :value="n+5"></el-option>
                            </el-select>
                        </el-col>
                    </el-row>

                </div>
            </group-item>

            <group-item title="添加试题">
                <div slot="content">
                    <el-row>
                        <el-col :span="6"><el-button plain size="mini" class="my-btn" @click="addTi(1)">单选题</el-button></el-col>
                        <el-col :span="6"><el-button plain size="mini" class="my-btn" @click="addTi(2)">多选题</el-button></el-col>
                        <el-col :span="6"><el-button plain size="mini" class="my-btn" @click="addTi(11)">判断题</el-button></el-col>
                        <el-col :span="6"><el-button type="warning" plain size="mini" class="my-btn" @click="addTi(9)">空白块</el-button></el-col>
                    </el-row>
                    <el-row style="margin-top: 16px">
                        <el-col :span="6"><el-button type="primary" plain size="mini" class="my-btn" @click="addTi(3)">填空题</el-button></el-col>
                        <el-col :span="6"><el-button type="primary" plain size="mini" class="my-btn" @click="addTi(4)">解答题</el-button></el-col>
                        <el-col :span="6"><el-button type="primary" plain size="mini" class="my-btn" @click="addTi(5)">语文作文</el-button></el-col>
                        <el-col :span="6"><el-button type="primary" plain size="mini" class="my-btn" @click="addTi(6)">英语作文</el-button></el-col>
                    </el-row>

                </div>
            </group-item>
            <group-item title="其他设置">
                <div slot="content">
                    <el-row style="text-align: center;">
                        <el-col :span="4">AB卷</el-col>
                        <el-col :span="8"><el-switch v-model="leftForm.paperAB" @change="abChange"></el-switch></el-col>
                        <el-col :span="4">页脚</el-col>
                        <el-col :span="8"><el-switch v-model="leftForm.footer"></el-switch></el-col>

                    </el-row>

                </div>
            </group-item>
            <group-item title="客观题设置" v-if="rightForm.chunkId != null && (rightForm.chunkType==1 || rightForm.chunkType==2 || rightForm.chunkType==11)" >
                <div slot="content">
                    <el-row>
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">题数量:</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1"  v-model="rightForm.tiCount" @change="tiCountChange"></el-input-number>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px">
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">每列行数:</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1" :max="6"  v-model="rightForm.tiRow" @change="tiRowChange"></el-input-number>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px" v-if="rightForm.chunkType!=11">
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">选项数:</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1" :max="12"  v-model="rightForm.anwCount" @change="tiAnwCountChange"></el-input-number>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px">
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">开始序号:</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1" :max="300"  v-model="rightForm.startSeq" @change="tiStartSeqChange"></el-input-number>
                        </el-col>
                    </el-row>
                </div>
            </group-item>
            <group-item title="填空题设置" v-if="rightForm.chunkId != null && rightForm.chunkType==3" >
                <div slot="content">
                    <el-row>
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">题数量:</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1"  v-model="rightForm.tiCount" @change="tiFilBlankChanged"></el-input-number>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px" v-if="rightForm.chunkType!=11">
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">每行题数:</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1" :max="4"  v-model="rightForm.anwCount" @change="tiFilBlankChanged"></el-input-number>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px">
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">起始题号:</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1" :max="300"  v-model="rightForm.startSeq" @change="tiStartSeqChange"></el-input-number>
                        </el-col>
                    </el-row>
                </div>
            </group-item>

            <group-item title="作文题设置" v-if="rightForm.chunkId != null && rightForm.chunkType==5" >
                <div slot="content">
                    <el-row>
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">字数:</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1" :step="22" v-model="rightForm.tiCount" @change="tiCompositionChanged"></el-input-number>
                        </el-col>
                    </el-row>

                </div>
            </group-item>
            <group-item title="解答题设置" v-if="rightForm.chunkId != null && rightForm.chunkType==4" >
                <div slot="content">

                    <el-row style="margin-top: 10px">
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">行数:</span>
                        </el-col>
                        <el-col :span="18">
                            {{rightForm.aqRows}}
                        </el-col>
                    </el-row>
                </div>
            </group-item>
            <group-item title="英语作文设置" v-if="rightForm.chunkId != null && rightForm.chunkType==6" >
                <div slot="content">
                    <el-row>
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">行数:</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1"  v-model="rightForm.englishRow" @change="tiEnglishChanged"></el-input-number>
                        </el-col>
                    </el-row>

                </div>
            </group-item>
            <group-item title="空白块设置" v-if="rightForm.chunkId != null && rightForm.chunkType==9" >
                <div slot="content">
                    <el-row>
                        <el-col :span="6" class="label">
                            <span style="font-size: 14px;">高度(毫米):</span>
                        </el-col>
                        <el-col :span="18">
                            <el-input-number size="mini" :min="1"  v-model="rightForm.height9" @change="tiEmptyBlockChanged"></el-input-number>
                        </el-col>
                    </el-row>

                </div>
            </group-item>

            <group-item title="题块操作" v-if="rightForm.chunkId != null">
                <div slot="content">
                    <el-row>
                        <el-col :span="8"><el-button  icon="el-icon-top" size="mini" @click="upDown(0)">上移</el-button></el-col>
                        <el-col :span="8"><el-button  icon="el-icon-bottom" size="mini" @click="upDown(1)">下移</el-button></el-col>
                        <el-col :span="8"><el-button type="danger" icon="el-icon-delete" size="mini" @click="onDeleteTi">删除</el-button></el-col>
                    </el-row>
                </div>
            </group-item>
            <el-row style="margin: 15px 0px;">
                <el-col :span="8"><el-button type="primary" icon="el-icon-view" size="mini" :disabled="!canSave" @click="preview">预览</el-button></el-col>
                <el-col :span="8"><el-button type="primary" icon="el-icon-folder" size="mini" :disabled="!canSave" @click="save(null)">保存</el-button></el-col>
                <el-col :span="8"><el-button type="primary" icon="el-icon-download" size="mini" :disabled="!canSave" @click="doanloadPdf">下载</el-button></el-col>
            </el-row>
            <group-item title="题目汇总" v-if="summaryList != null && summaryList.length > 0">
                <div slot="content" style="padding: 10px 15px">
                    <el-descriptions class="margin-top" :column="1" border  :labelStyle="{width: '90px'}">
                        <el-descriptions-item v-for="item in summaryList">
                            <template slot="label">
                                {{item.label}}
                            </template>
<!--                            {{("score"in item?"每题"+(item.score?item.score:0)+"分,":"")}}-->
                            共{{item.count}}道题， 共{{item.totalScore!=null?item.totalScore:0}}分
                        </el-descriptions-item>
                    </el-descriptions>

                </div>
            </group-item>


        </div>
        <previewDialog ref="previewDialog"></previewDialog>
        <tiSettingDialog ref="tiSettingDialog" :ti-setting="selTiSetting" @ok="tiSettingOK"></tiSettingDialog>
    </div>
</template>

<script>

    import groupItem from "./groupItem";
    import paperSheet from "./paperSheet";
    import previewDialog from "./previewDialog";
    import tiSettingDialog from "./tiSettingDialog";
    import * as anwCardUtils from './anwCardUtils'
    import * as simpleApi from "request/simple";
    import {mapActions, mapState} from "vuex";
    import {getFillBlankContent, getObject3HeightPx, getTiType} from "./anwCardUtils";
    import XEUtils from "xe-utils";

    export default {
        name: "cardView",
        components:{
            groupItem, paperSheet, previewDialog, tiSettingDialog
        },
        computed: {
            ...mapState({
                pageConfig: state => state.card.pageConfig,
                tiSelect: state => state.card.tiSelect,
                tiChange: state => state.card.tiChange,
                tiIndex: state => state.card.tiIndex,
                tiAction: state => state.card.tiAction,
                isCanSave: state => state.card.isCanSave,
            }),
        },
        data() {
            return {
                content: '', loading: false, examId: null, examData: null, dbObject: null, inputPage: null, dpi: 0,
                selTiSetting: null, canSave: true,
                leftForm: {examType: 2, examNoType: 2, examNoCount: 12, layoutDesc: '两栏适用于A3、8K、B4纸张', paperAB: false, footer: true},
                rightForm: { chunkId: null, seq: null, chunkType: null, tiCount: null, tiRow: null, anwCount: null, startSeq: null, height9: null, score: null, },
                summaryList: [],
            }
        },
        watch: {
            tiSelect:{
                deep: true, immediate:true,
                handler(n, o){
                    if(n){
                        // console.log('card view', n);
                        this.rightForm = { chunkId: n.id, seq: null, chunkType: n.type, row: n.row, tiCount: n.allCount, tiRow: n.row, aqRows: n.rows, englishRow: n.row, height9: n.height9, anwCount: n.anwCount, startSeq: n.startSeq, score: n.score, };
                    }else{
                        this.rightForm = { chunkId: null, seq: null, chunkType: null, tiCount: null, tiRow: null, englishRow: null, anwCount: null, startSeq: null, height9: null, score: null, };
                    }
                }
            },
            tiAction: {
                deep: true, immediate: true,
                handler(n, o) {
                    console.log("tiAction summary", n);
                    if(n.action == 'summary') this.getSummary();
                    if(n.action == 'headerTitle') {
                        if (this.dbObject != null) {this.dbObject.header.title = n.title;this.dbObject.header.name = n.text;}
                    }
                }
            },
            isCanSave: {
                deep: true, immediate: true,
                handler(n, o) {
                    this.canSave = n;
                }
            },
        },
        mounted() {
            let qid = this.$route.query.id;
            if(qid != null){
                let that = this;
                this.$nextTick(()=>{
                    let {dpi, pxHeight, pxH, pxCha, mmH, mmCha} = that.$refs.paperSheet.getPageArea();
                    that.dpi = dpi;
                    that.examId = parseInt(qid);
                    that.loadOne();
                });
            }
        },
        methods: {
            ...mapActions('card',['setPageConfig', 'setColumns', 'setExamNoType', 'tiAdd', 'setTiIndex', 'setTiChange', 'deleteTi', 'changeTiPos', 'setIsPreview']),
            loadOne(){
                this.loading = true;
                let param = { controllerName: 'exam/scantron', methodName: '/get',  param: { id: this.examId}};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    if (code == 0) {
                        let that = this;  this.examData = data.location;
                        this.chaiLocation(data.name);
                        setTimeout(function (){that.getSummary();}, 1000);

                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error", error);
                });
            },
            getSummary(){
                if(("paperSheet") in this.$refs){
                    console.log("card view getSummary");
                    let summaryList = this.$refs.paperSheet.getSummary();
                    let totalScore=0, count = 0;
                    XEUtils.arrayEach(summaryList, (item, key) => {
                        count = count+(item.count>0?item.count:0); totalScore = totalScore+(item.totalScore>0?item.totalScore:0);
                    });
                    summaryList.push({label: '合计', count: count, totalScore: totalScore});
                    this.summaryList = summaryList;
                }
            },
            getTiType(v){
                return anwCardUtils.getTiTitle(v);
            },
            abChange(v){
                console.log("abChange", v);
                this.pageConfig.paperAB = v;
                this.setPageConfig(this.pageConfig);
            },
            tiCountChange(v){
                this.setTiChange({id: this.rightForm.chunkId, count: this.rightForm.tiCount});
            },
            tiScoreChange(v){
                this.setTiChange({id: this.rightForm.chunkId, score: this.rightForm.score});
            },
            tiRowChange(v){
                this.setTiChange({id: this.rightForm.chunkId, row: this.rightForm.tiRow});
            },
            tiAnwCountChange(v){
                this.setTiChange({id: this.rightForm.chunkId, anwCount: this.rightForm.anwCount});
            },
            tiStartSeqChange(v){
                this.setTiChange({id: this.rightForm.chunkId, startSeq: this.rightForm.startSeq});
            },
            tiFilBlankChanged(){
                console.log("tiFilBlankChanged", this.rightForm.tiRow, this.rightForm.anwCount);
                this.setTiChange({id: this.rightForm.chunkId, count: this.rightForm.tiCount, row: Math.ceil(this.rightForm.tiCount/this.rightForm.anwCount),
                    anwCount: this.rightForm.anwCount, clearFillBlank: true,
                });
            },
            tiCompositionChanged(){
                console.log("tiCompositionChanged", this.rightForm.tiCount);
                this.setTiChange({id: this.rightForm.chunkId, count: this.rightForm.tiCount});
            },
            tiEnglishChanged(){
                console.log("tiEnglishChanged", this.rightForm.englishRow);
                this.setTiChange({id: this.rightForm.chunkId, englishRow: this.rightForm.englishRow});
            },
            tiEmptyBlockChanged(){
                console.log("tiEmptyBlockChanged", this.rightForm.height9);
                this.setTiChange({id: this.rightForm.chunkId, height9: this.rightForm.height9});
            },
            upDown(v){
                this.changeTiPos({ id:this.rightForm.chunkId, dir:v});
            },
            onDeleteTi(){
                this.deleteTi(this.rightForm.chunkId);
            },
            chaiLocation(paperTitle){
                if(this.examData != null){
                    let obj = JSON.parse(this.examData);
                    console.log("chaiLocation", paperTitle, obj);
                    let examType = anwCardUtils.getPaperNo(obj.paperType);
                    this.leftForm.examType = examType;
                    this.setColumns(examType);
                    let page1 = obj.pageSheets[0];
                    this.pageConfig.examNoTotal = page1.ticketNum.count;
                    this.setPageConfig(this.pageConfig);
                    this.leftForm.examNoCount = page1.ticketNum.count;
                    let hTitle = obj.paperName.name || paperTitle;
                    let header = {title: hTitle, name: hTitle, row1: obj.paperName.row1, row2: obj.paperName.row2, notice: obj.paperName.notice, regNoTitle: page1.ticketNum.title};
                    let chunkList = [], chunkList0 = [], chunkList1 = [];
                    chunkList0 = this.heBranch(obj.pageSheets[0].sections, null);
                    if(obj.pageSheets.length > 1){
                        chunkList1 = this.heBranch(obj.pageSheets[1].sections, chunkList0);
                    }

                    chunkList = [...chunkList0, ...chunkList1];
                    console.log("chaiLocation", header, chunkList);
                    this.dbObject = {header: header, chunkList: chunkList};
                }else{
                    let header = {title: paperTitle, name: paperTitle, row1: null, row2: null, notice: null};
                    this.dbObject = {header: header, chunkList: []};
                    this.pageConfig.examNoTotal = 12;
                    this.setPageConfig(this.pageConfig);
                }
            },
            heBranch(sections1, chunkList0){
                let chunkList1 = [], zgIx = null;
                if(chunkList0 != null && chunkList0.length > 0) zgIx = chunkList0[chunkList0.length-1].index;
                for (let i=0; i<sections1.length; i++){
                    let sect = sections1[i];
                    console.log("sect index", zgIx, sect.index);
                    if(sect.type == "Object"){
                        let alCount = 0, row = 0, rows = 0, anwCount = 0, tiType = 0, title=null, titleHeight=null, ixList1 = [];
                        for (let j=0; j<sect.contents.branch.length; j++){
                            let branch = sect.contents.branch[j];
                            let {chooseCount, subAreaTopicType, position, ixList, numList} = branch;
                            tiType = anwCardUtils.getTiType(subAreaTopicType); anwCount = chooseCount;
                            if(row < numList.length) row = numList.length;
                            alCount = alCount + numList.length;
                            ixList1 = [...ixList1, ...ixList];
                        }
                        let ixKey = sect.index;
                        if(zgIx != ixKey){
                            let a1 = {id: null, row: row, count: alCount, allCount: alCount, rows: Math.ceil(alCount/row), type: tiType, title: sect.title,
                                titleHeight: null, anwCount: anwCount, ixList: ixList1, startSeq: sect.startSeq, index: sect.index};
                            if(("id" in sect)) a1.id=sect.id; else a1.id=this.$uuid.v4();
                            if(("title" in sect)) a1.title=sect.title; else a1.title=anwCardUtils.getTiTitle(tiType);
                            if(("titleHeight" in sect)) a1.titleHeight=sect.titleHeight*this.dpi+10; else a1.titleHeight=anwCardUtils.getTitleHeight(a1.title);
                            if(("answers" in sect)) a1.answers=sect.answers;
                            if(("score" in sect)) a1.score=sect.score;
                            console.log("chuck", a1);
                            chunkList1.push(a1);
                        }else{
                            let f = XEUtils.find(chunkList1, (item) =>item.id == ixKey);
                            if(f){ f.count = f.count + alCount; f.allCount = f.allCount + alCount; f.rows = Math.ceil(f.alCount/f.row)}
                            else {
                                f = XEUtils.find(chunkList0, (item) =>item.id == ixKey);
                                if(f){ f.count = f.count + alCount; f.allCount = f.allCount + alCount; f.rows = Math.ceil(f.alCount/f.row)}
                            }
                        }
                        zgIx = ixKey;
                    }else if(sect.type == "emptyBlock"){
                        let a1 = {id: this.$uuid.v4(), type: 9, height9: 30};
                        if("height9" in sect) a1.height9 = sect.height9;
                        if("id" in sect) a1.id = sect.id;
                        chunkList1.push(a1);
                    }else{
                        let branch = sect.contents.branch[0];
                        let ixKey = sect.index;
                        if(zgIx != ixKey){
                            if(sect.type == "FillBlank"){
                                let allCount = ("count" in sect)?sect.count:branch.ixList.length;
                                let anwCount = ("anwCount" in sect)?sect.anwCount:0;
                                let startSeq = branch.numList[0];
                                let row = Math.ceil(allCount/anwCount), content = sect.contents.content?sect.contents.content:'';
                                let rows = content.split("\n").length, myHeight = sect.contents.position.height*this.dpi;
                                console.log("FillBlank height", myHeight, "rows", rows);
                                let chuk = {id: this.$uuid.v4(), row: row, count: allCount, allCount: allCount, rows: rows, type: 3, title: sect.title,
                                    titleHeight: anwCardUtils.getAnwTitlePx(), fillBlank: content, startSeq: startSeq,
                                    fillBlankHeight: myHeight, anwCount: anwCount, ixList: branch.ixList, index: sect.index };
                                if(("titleHeight" in sect)) chuk.titleHeight=sect.titleHeight*this.dpi+10; else chuk.titleHeight=anwCardUtils.getTitleHeight(chuk.title);
                                if(("score" in sect)) chuk.score=sect.score;
                                chunkList1.push(chuk);
                            }
                            if(sect.type == "AnswerQuestion"){
                                let row = ("answerChildList" in sect)?sect.answerChildList.length:7;
                                let chuk = {id: this.$uuid.v4(), row: row, count: null, allCount: null, rows: null, type: 4, title: sect.title, titleHeight: anwCardUtils.getAnwTitlePx(),
                                    fillBlank: null, anwCount: null, answerChildList: anwCardUtils.defaultAnswerChilds(row, this.dpi), answerQuestionHeight: anwCardUtils.getObjectAnswerHeightPx(row, this.dpi), ixList: branch.ixList,  index: sect.index }
                                if(("titleHeight" in sect)) chuk.titleHeight=sect.titleHeight*this.dpi; else chuk.titleHeight=19;
                                if(("answerChildList" in sect) && sect.answerChildList != null) {chuk.answerChildList = sect.answerChildList;chuk.answerQuestionHeight = XEUtils.sum(sect.answerChildList, 'height'); }
                                if(("score" in sect)) chuk.score=sect.score;
                                console.log("AnswerQuestion", chuk);
                                chunkList1.push(chuk);
                            }
                            if(sect.type == "Composition"){
                                let allCount = ("compositionCount" in sect)?sect.compositionCount:900;
                                let chuk = {id: this.$uuid.v4(), row: null, count: allCount, allCount: allCount, rows: Math.ceil(allCount/22), type: 5, title: sect.title, titleHeight: anwCardUtils.getAnwTitlePx(),
                                    fillBlank: null, anwCount: null, compositionHeight: anwCardUtils.getCompositionHeightPx(Math.ceil(allCount/22), this.dpi), ixList: branch.ixList, index: sect.index };
                                if(("titleHeight" in sect)) chuk.titleHeight=sect.titleHeight*this.dpi; else chuk.titleHeight=anwCardUtils.getTitleHeight(chuk.title);
                                if(("score" in sect)) chuk.score=sect.score;
                                chunkList1.push(chuk);
                            }
                            if(sect.type == "EnglishComposition"){
                                let row = ("row" in sect)?sect.row:7;
                                let chuk = {id: this.$uuid.v4(), row: row, count: null, allCount: null, rows: row, type: 6, title: sect.title, titleHeight: anwCardUtils.getAnwTitlePx(),
                                    fillBlank: null, anwCount: null, englishChildList: anwCardUtils.defaultEnglishChilds(row, this.dpi), englishHeight: 4.2*15*this.dpi, ixList: branch.ixList, index: sect.index };
                                if(("titleHeight" in sect)) chuk.titleHeight=sect.titleHeight*this.dpi; else chuk.titleHeight=anwCardUtils.getTitleHeight(chuk.title);
                                if(("englishChildList" in sect) && sect.englishChildList != null) {chuk.englishChildList = sect.englishChildList;chuk.englishHeight = XEUtils.sum(sect.englishChildList, 'height'); }
                                if(("score" in sect)) chuk.score=sect.score;
                                chunkList1.push(chuk);
                            }
                            zgIx = ixKey;
                        }else{
                            if(sect.type == "AnswerQuestion"){
                                let pre = null;
                                if(chunkList0 != null) pre = chunkList0[chunkList0.length-1];
                                if(pre == null) pre = sections1[i-1];
                                let title = sect.title || pre.title;
                                let titleHeight = sect.titleHeight || pre.titleHeight;
                                let answerChildList = [...pre.answerChildList, ...sect.answerChildList];
                                let chuk = {id: this.$uuid.v4(), row: null, count: null, allCount: null, rows: answerChildList.length, type: 4, title: title,
                                    titleHeight: titleHeight*this.dpi+10, answerChildList: answerChildList,
                                    fillBlankHeight: null, anwCount: null, ixList: branch.ixList, index: sect.index };
                                if(("score" in sect)) chuk.score=sect.score;
                                console.log("AnswerQuestion ", chuk);
                                if(chunkList0 != null) {
                                    console.log("chunkList0 != null", chunkList0.length);
                                    chunkList0[chunkList0.length-1] = chuk;
                                }else{
                                    chunkList1[i-1] = chuk;
                                }
                            }
                            if(sect.type == "FillBlank"){
                                let pre = null;
                                if(chunkList0 != null) pre = chunkList0[chunkList0.length-1];
                                if(pre == null) pre = sections1[i-1];
                                let title = sect.title || pre.title;
                                let titleHeight = sect.titleHeight || pre.titleHeight;
                                let allCount = ("count" in sect)?sect.count:branch.ixList.length + ("count" in pre)?pre.count:pre.content.branch[0].ixList.length;
                                let anwCount = ("anwCount" in sect)?sect.anwCount:2;
                                let row = Math.ceil(allCount/anwCount),
                                    content = sect.contents.content?sect.contents.content:('' + '\n');
                                console.log("FillBlank content", sect, content);
                                let rows = content.split("\n").length;
                                let chuk = {id: this.$uuid.v4(), row: row, count: allCount, allCount: allCount, rows: rows, type: 3, title: title,
                                    titleHeight: titleHeight*this.dpi+10, fillBlank: content,
                                    fillBlankHeight: null, anwCount: anwCount, ixList: branch.ixList, index: sect.index };
                                if(("score" in sect)) chuk.score=sect.score;
                                if(chunkList0 != null) {
                                    console.log("chunkList0 != null", chunkList0.length);
                                    chunkList0[chunkList0.length-1] = chuk;
                                }else{
                                    chunkList1[i-1] = chuk;
                                }
                            }
                            if(sect.type == "EnglishComposition"){
                                let pre = null;
                                if(chunkList0 != null) pre = chunkList0[chunkList0.length-1];
                                if(pre == null) pre = sections1[i-1];
                                let englishChildList = [...sect.englishChildList, ...pre.englishChildList];
                                let title = sect.title || pre.title;
                                let titleHeight = sect.titleHeight || pre.titleHeight;
                                let englishHeight = XEUtils.sum(englishChildList, 'height');
                                let row = englishChildList.length;
                                let chuk = {id: this.$uuid.v4(), row: row, count: null, allCount: null, rows: row, type: 6, title: title,
                                    titleHeight: titleHeight*this.dpi+10, fillBlank: null, englishChildList: englishChildList, englishHeight: englishHeight,
                                    anwCount: null, ixList: branch.ixList, index: sect.index };
                                if(("score" in sect)) chuk.score=sect.score;
                                console.log("zgIx != ixKey", zgIx, ixKey, chuk);
                                if(chunkList0 != null) {
                                    console.log("chunkList0 != null", chunkList0.length);
                                    chunkList0[chunkList0.length-1] = chuk;
                                }else{
                                    chunkList1[i-1] = chuk;
                                }
                            }
                        }
                    }
                }
                return chunkList1;
            },
            addTi(v){
                let t = {id: null, tiType: v, tiCount: 5, anwCount: 4, tiRow: 5, startSeq: this.tiIndex+1, score: 0, time: (new Date()).getMilliseconds()};
                if(v == 3) {t.tiCount = 2; t.anwCount = 2; }
                if(v == 4) {t.tiCount = 1; }
                if(v == 5) {t.tiCount = 900; }
                if(v == 9) {t.height9 = 30; }
                this.selTiSetting = t;
                this.$refs.tiSettingDialog.showDialog();
            },
            tiSettingOK(o){
                if(o.tiType == 9){
                    this.tiAdd({ action: 'add', type: o.tiType, height9: o.height9});
                }else{
                    this.tiAdd({ action: 'add', type: o.tiType, name: o.name, tiCount: o.tiCount, anwCount: o.anwCount, startSeq: o.startSeq, tiRow: o.tiRow, score: o.score});
                }
                if(o.tiType<4 || o.tiType ==11) this.setTiIndex(this.tiIndex + o.tiCount);
            },
            examTypeChange(v){
                console.log("examNoTotalChange", v);
                if(v > 0){
                    this.setColumns(v);
                }
                if(v == 1) this.leftForm.layoutDesc = '一栏适用于A4、16K、B5纸张';
                if(v == 2) this.leftForm.layoutDesc = '两栏适用于A3、8K、B4纸张';
            },
            examNoTotalChange(v){
                console.log("examNoTotalChange", v);
                if(v > 0){
                    this.pageConfig.examNoTotal = v;
                    this.setPageConfig(this.pageConfig);
                }
            },

            save(callback){
                let that = this;
                let pd = this.$refs.paperSheet.getPageData();
                if(this.examType == 1 && pd.length > 2){this.$message({ type: 'error', message: '答题卡最多支持2页！'}); return ;}
                if(this.examType == 2 && pd.length > 4){ this.$message({ type: 'error', message: '答题卡最多支持4页！'});return ;}
                this.loading = true;
                this.setTiIndex(0);
                let exam = {version: 2, paperType: anwCardUtils.getPaperType(this.leftForm.examType)};
                let exPage = this.$refs.paperSheet.callJson();
                let ps = exPage.paper;
                exam["paperName"] = ps.paperName;
                exam["row1"] = ps.row1;
                exam["row2"] = ps.row2;
                exam["notice"] = ps.notice;
                exam["pageSheets"] = ps.pageSheets;
                if(("noAnswerMark" in ps)) { exam["noAnswerMark"] = ps.noAnswerMark; }
                console.log("exam", exam);
                let topicColl = exPage.topicColl;
                console.log("topicColl", topicColl);
                let para = {id: this.examId, name: ps.paperName.name, location: JSON.stringify(exam), topicCollections: JSON.stringify(topicColl)};
                let param = { controllerName: 'exam/scantron', methodName: '/saveOrUpdate', param: para};
                simpleApi.post(param).then(({code, data}) =>  {
                    if (code == 0) {
                        this.$message({ type: 'success', message: '保存成功！'});
                        that.loading = false;
                        if(callback) callback();
                    }
                }).catch((error) => {
                    console.log("error", error);
                    this.$message({ type: 'error', message: '保存失败！'});
                });
            },
            preview(){
                let that = this;
                this.save(function () {
                    that.setIsPreview(true);
                    let pd = that.$refs.paperSheet.getPageData();
                    that.$refs.previewDialog.setPageData(pd);
                    that.$refs.previewDialog.showDialog(that.examId, "preview");
                })

            },
            doanloadPdf(){
                let that = this;
                this.loading = true;
                this.save(function () {
                    that.loading = true;
                    that.setIsPreview(true);
                    let pd = that.$refs.paperSheet.getPageData();
                    that.$refs.previewDialog.setPageData(pd);
                    that.$refs.previewDialog.showDialog(that.examId, "pdf");
                    setTimeout(function () {
                        that.$refs.previewDialog.downloadPdf(function () {
                            that.loading = false;
                        });
                    }, 1000);
                })
            },

        }
    }
</script>

<style scoped  lang="scss" type="text/scss" rel="stylesheet/scss">

    .cardView{
        text-shadow: transparent 0px 0px 0px, rgb(0 0 0 / 68%) 0px 0px 0px !important;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        padding-top: 20px;
        background-color: #eee;
        color: #333;
        .exam-title {
            border: 1px solid #8b9196;
        }
        .left-oper {
            position: sticky;
            position: -webkit-sticky;
            top: 0px;
            z-index: 99;
            width: 380px;
            background-color: white;
            padding: 20px 10px;
            max-height: 110vh;
            -webkit-border-radius: 8px 8px 8px 8px;
            .my-btn {
                width: 80px;
            }
            .my-row {
                margin-top: 10px;
            }
        }
        .right-oper {
            position: sticky;
            position: -webkit-sticky;
            top: 0px;
            width: 320px;
            background-color: white;
            padding: 20px 10px;
            margin-left: 10px;
            max-height: 90vh;
            -webkit-border-radius: 8px 8px 8px 8px;
            .my-btn {
                width: 80px;
            }
        }

    }
</style>
