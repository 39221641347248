<template>
    <div class="compositionRow">
        <div class="title no-row" >
            <span v-for="index of rectLen"  class="item-no" >
                <span class="f100" v-for="f in f100" v-if="(start+index)==f.value">{{f.value}}</span>
            </span>

        </div>
    </div>
</template>

<script>
export default {
    name: "compositionRow",
    props: {
        f100: {
            type: Object
        },
        start: {
            type: Number
        },
        topLine: {
            type: Boolean, default: false
        },
        bottomLine: {
            type: Boolean, default: false
        },
    },
    data(){
        return {
            rectLen: 22,
        }
    },
    methods:{
        getLineStyle(){
            let o = {};
            // if(this.topLine) o["borderTop"] = '1px solid black';
            // if(this.bottomLine) o["borderBottom"] = '1px solid black';
            // console.log("getLineStyle", this.topLine, this.bottomLine);
            return o;
        },
        getItemNoStyle(i){
            if(i==1) return {borderRight: '1px solid black'};
            if(i==this.len) return {borderLeft: '1px solid black'};
            return { borderLeft: '1px solid black', borderRight: '1px solid black'};
        },
    },
}
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">
    .compositionRow{
        .title {
            display: flex;
            flex: none;
            justify-content: space-around;
            box-sizing: content-box;
            //border-bottom: 1px solid black;
            //border-left: 1px solid black;
            //border-right: 1px solid black;
        }
        .no-row {
            border-bottom: none;
        }
        .item{
            float: left;
            width: 26px;
            height: 25px;
        }
        .item-no{
            position: relative;
            float: left;
            width: 7.5mm;
            height: 8mm;
            box-sizing: border-box;
            line-height: 1.5mm;
            margin-bottom: 1.5mm;
            border: 1px solid #000;
            font-size: 12px;
        }
        .f100{
            position: absolute;
            font-size: 8px;
            top: 8mm;
            left: 0mm;
        }
    }
</style>

