import ar from "element-ui/src/locale/lang/ar";

export const getInteger = (v, d) => {
    if(!v || v == null) return d;
    return parseInt(v);
}

export const getFloat = (v, d) => {
    if(!v || v == null) return d;
    return parseFloat(v);
}

export const getPageMMSize = () => {
    return {width: 210, height: 297};
}
//获取定位点内内容的长宽
export const getPageContentSize = () => {
    return {width: 178, height: 262};
}

export const getPageHeaderHeight = () => {
    return 110;
}

//获取最大列数
export const maxCols = (anwCount) => {
    if(anwCount == 2) return 8;
    if(anwCount == 3) return 6;
    if(anwCount == 4) return 5;
    if(anwCount == 5) return 4;
    if(anwCount >= 6 && anwCount <= 7) return 3;
    if(anwCount > 7) return 2;
    return 1;
}

export const getColWidth = (anwCount) => {
    return 6 * (anwCount+1);
}

export const getAnwHeight = (anwRow) => {
    return 5 * (anwRow) + 4;
}

export const getTitleHeight = (s) => {
    let len = s.split('<p');
    // console.log("getTitleHeight", len.length);
    return 17*len.length;
}

export const getChunkHeight = (count, row, anwCount) => {
    let mc = maxCols(anwCount);
    let rows = Math.ceil(Math.ceil(count / row) / mc);
    // console.log("getChunkHeight", count, row, anwCount, rows);
    if(rows > 1){
        if(count - (rows-1)*row*mc < row){
            // console.log("getChunkHeight2", (rows-1)*(5 * row + 4), 5 * (count - (rows-1)*row*mc) + 4);
            return (rows-1)*(5 * row + 4) + 5 * (count - (rows-1)*row*mc) + 4;
        }
        return rows*(5 * (row) + 4);
    }
    return 5 * (row) + 4;
}

export const getChunkHeightPx = (count, row, anwCount, dpi) => {
    return getChunkHeight(count, row, anwCount)* dpi;
}
export const getObjectHeightPx = (tiType) => {
    if(tiType == 3){
        return 40;
    }
    return 40;
}

export const getObject3HeightPx = (row, dpi) => {
    return (8 *row) * dpi + 10;
}

export const getObject3Content = (count, anwCout, start) => {
    let first = "<p>&ensp;</p>";
    let s = first;
    let blankCount = 40;
    if(anwCout == 1) blankCount = 73;
    if(anwCout == 2) blankCount = 35;
    if(anwCout == 3) blankCount = 20;
    if(anwCout == 4) blankCount = 15;
    let row = Math.ceil(count/anwCout);
    for(let i=0; i<row; i++){
        s = s + '<p style="text-align: left;font-size: 14px; line-height: 2.4;">&ensp;&ensp;&ensp;&ensp;';
        for (let j=0; j<anwCout; j++){
            if((start+i*anwCout+j) <= count){
                s = s + (start+i*anwCout+j) + '．​';
                for (let c=0; c<blankCount; c++) s = s + '_';
                s =s+'&ensp;&ensp;';
            }
        }
        s = s + '</p>';
    }
    s = s+'<p><br data-mce-bogus="1"></p>';

    return s;
}

export const getFillBlankContent = (count, anwCout, start) => {
    let s = "";
    let blankCount = 40;
    if(anwCout == 1) blankCount = 73;
    if(anwCout == 2) blankCount = 35;
    if(anwCout == 3) blankCount = 20;
    if(anwCout == 4) blankCount = 15;
    let row = Math.ceil(count/anwCout);
    let iIdx = start||1;
    for(let i=0; i<row; i++){

        for (let j=0; j<anwCout; j++){
            if((i*anwCout+j) < count){
                if(j == 0) s = s + iIdx + '.  ';
                else s = s + '  '+iIdx + '.  ';
                for (let c=0; c<blankCount; c++) s = s + '_';
                iIdx ++;
                // console.log("iIdx", iIdx, s);
            }
        }
        if(i<(row-1)) s = s + '\n';
    }

    return s;
}

export const getObjectAnswerHeightPx = (row, dpi) => {
    return (9 *row) * dpi;
}

export const getCompositionHeightPx = (row, dpi) => {
    return (9.5 *row) * dpi + 20;
}

export const defaultAnswerChilds = (row, dpi) => {

    let s = '<p style="text-align: left; font-size: 14px; line-height: 1.8;">&ensp;</p>';
    let childs = [];
    for (let i=0; i<row; i++){
        childs.push({html: s, height: (9) * dpi});
    }
    return childs;
}

export const getComposition100 = (rows, start) => {
    let n = start, lst = [];
    for (let i=0; i<rows; i++){
        for (let j=0; j<22; j++){
            if(n %100==0){
                lst.push({row: i, col: j, value: n});
            }
            n++;
        }
    }
    return lst;
}

export const defaultEnglishChilds = (row, dpi) => {

    let blankCount = 83;
    let line = '<p></p><p style="text-align: left; font-size: 14px;">&ensp;&ensp;';
    for (let j=0; j<blankCount; j++) line = line+ "_";
    line = line+ "</p>";
    let childs = [];
    for (let i=0; i<row; i++){
        childs.push({html: line, height: (9.8) * dpi});
    }
    childs.push({html: '<p></p>', height: (4.2) * dpi});
    return childs;
}

export const getAnwHeightPx = (anwRow, dpi) => {
    return (5 * (anwRow) + 4)* dpi;
}

export const getAnwTitlePx = () => {
    return 22;
}

export const getPaperType = (v) => {
    if(v == 1) return "A4";
    if(v == 2) return "A3";
    return "";
}
export const getPaperNo = (v) => {
    if(v == "A4") return 1;
    if(v == "A3") return 2;
    return "";
}

export const getTiTitle = (v) => {
    if(v == 1) return "单选题";
    if(v == 11) return "判断题";
    if(v == 2) return "多选题";
    if(v == 3) return "填空题";
    if(v == 4) return "解答题";
    if(v == 5) return "语文作文";
    if(v == 6) return "英语作文";
    return "";
}

export const getTopicType = (v) => {
    if(v == 1) return "SingleChoice";
    if(v == 11) return "Judge";
    if(v == 2) return "MultiChoice";
    if(v == 3) return "FillBlank";
    if(v == 4) return "AnswerQuestion";
    if(v == 5) return "Composition";
    if(v == 6) return "EnglishComposition";
    if(v == 9) return "emptyBlock";

    return "";
}

export const getTiType = (v) => {
    if(v == "SingleChoice") return 1;
    if(v == "Judge") return 11;
    if(v == "MultiChoice") return 2;
    if(v == "FillBlank") return 3;
    if(v == "AnswerQuestion") return 4;
    if(v == "Composition") return 5;
    if(v == "EnglishComposition") return 6;

    return 0;
}
