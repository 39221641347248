<template>
    <div class="page2Point">
        <div class="point" ref="point"></div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "posPoint",
        data() {
            return {}
        },
        computed: {
            ...mapState({
                pageConfig: state => state.card.pageConfig,
            }),
            myPos(){
                return {
                    left: this.left+'mm', top: this.top+'mm'
                }
            }
        },
        methods: {
            callJson(config, pageConfig){
                let {dpi} = config;
                let {pageLeft, pageTop, leftTop, ptWidth, ptHeight} = pageConfig;
                let {clientWidth, clientHeight, offsetLeft, offsetTop} = this.$refs.point;
                let left = ((offsetLeft) /dpi + 210 +15 - 6);
                if(this.pageConfig.columns == 1) left = ((offsetLeft) /dpi - 6);
                let top = ((offsetTop) /dpi + 15-12);
                let p = {width: 12,height: 12, left: left, top: top, left_begin: left/ptWidth, top_begin: top/ptHeight,
                    left_end: (left+12)/ptWidth, top_end: (top+12)/ptHeight, width_scale:12/ptWidth, height_scale:12/ptHeight };
                console.log("page2Point", p);
                return p;
            }
        },
    }
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">
    .page2Point{
        position: relative;
        border: 1px dashed #000;
        padding: 10px;
        margin-bottom: 10px;
        .point{
            margin: 0 auto;
            background-color: black;
            width: 12mm;
            height: 12mm;
        }
    }
</style>
