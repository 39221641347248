<template>
    <div class="previewDialog">
        <el-dialog  :visible.sync="dialogVisible" width="100%" :fullscreen="true"  v-loading="loading" @close="closePreview" :close-on-click-modal="false">
            <div slot="title">
                <el-button type="primary" :disabled="canClose" @click="cancel">关闭</el-button>
            </div>
            <paperSheet ref="paperSheet" id="previewSheet" :input-page="pageData" :isPreView="true"></paperSheet>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关闭</el-button>
                <el-button type="primary" :loading="loading" @click="savePdf(1)">保存图片</el-button>
                <el-button type="primary" :loading="loading" @click="savePdf(3)">生成pdf</el-button>
<!--                <el-button type="primary" :loading="loading" @click="savePdf(3)">在线pdf</el-button>-->
            </div>

        </el-dialog>
    </div>
</template>

<script>
    import paperSheet from "./paperSheet";
    import html2canvas from "html2canvas"
    import { saveAs } from 'file-saver';
    import {mapActions} from "vuex";
    export default {
        name: "previewDialog",
        data(){
            return {
                dialogVisible:false, loading: false, pageData: null, id: null, canClose: true,
            }
        },
        components:{ paperSheet },
        methods: {
            ...mapActions('card',[ 'setIsPreview']),
            showDialog(id, flag){
                this.id = id;
                this.dialogVisible = true;
                if(flag == 'pdf') this.loading = true;
                if(flag == 'preview') this.savePdfBackgroud();
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            setPageData(pd){
                this.pageData = pd;
            },
            savePdfBackgroud(){
                let that = this;
                that.canClose = true;
                setTimeout(function () {
                    that.$refs.paperSheet.saveImages(3, that.id, function () {
                        that.canClose = false;
                    });
                },800);

            },
            savePdf(flag){
                let that = this;
                this.loading = true;
                this.$refs.paperSheet.saveImages(flag, this.id, function () {
                    that.loading = false;
                });
            },
            downloadPdf(callback){
                let that = this;
                this.loading = true;
                this.$refs.paperSheet.saveImages(4, this.id, function () {
                    that.loading = false;
                    that.dialogVisible = false;
                    if(callback) callback();
                });
            },
            closePreview(){
                console.log("closePreview");
                this.setIsPreview(false);
            }
        }

    }
</script>

<style scoped  lang="scss" type="text/scss" rel="stylesheet/scss">
    .previewDialog{

        ::v-deep .el-dialog__body{
            padding: 0px;
        }
    }

</style>
