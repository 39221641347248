import axios from 'axios';
import store from '../store';
import XEUtils from "xe-utils";
import router from "../router";

const BASE_URI = '/api/simple';
axios.defaults.timeout = window.g.AXIOS_TIMEOUT;
//http://localhost:9080/  http://sso.icubespace.com:9080/"
axios.defaults.baseURL = process.env.NODE_ENV=='production'?window.g.PROD_HOST:window.g.DEV_HOST;
axios.defaults.headers = {
    'Company-url': 'http://www.szpcyl.com',
    'My-Email': '332899@qq.com',
    'X-Powered-By': 'raozq',
    'ke-Token': 'noLogin',
    'ke-Domain': 'WB',
    'Content-Type':'application/json;charset=UTF-8'
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
}
const getToken = function () {
    let myToken = XEUtils.isString(store.state.user.token) ;
    if(myToken){
        return store.state.user.token;
    }
    return 'noLogin';
}


export async function download(params) {

    console.log('params', params)
    let options = {
        url: BASE_URI + '/download',
        data: params,
        method: 'post',
        responseType: 'blob'
    };

    let myToken = getToken();
    if (myToken) {
        axios.defaults.headers['ke-Token'] = myToken;
        options.data.token =  myToken;
    }

    axios.request(options).then(res => {
        let blob = new Blob([res.data]);
        var contentDisposition = res.headers['content-disposition'];  //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        var patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
        var result = patt.exec(contentDisposition);
        var fileName = result[1];
        console.log('name', fileName)
        if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            let link = document.createElement("a");
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", false, false);
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
        }
    });
}

export async function downloadGet(params) {

    console.log('params', params)
    let options = {
        url: params.url,
        data: params,
        method: 'get',
        responseType: 'blob'
    };

    let myToken = getToken();
    if (myToken) {
        axios.defaults.headers['ke-Token'] = myToken;
        options.data.token =  myToken;
    }

    axios.request(options).then(res => {
        let blob = new Blob([res.data]);
        var contentDisposition = res.headers['content-disposition'];  //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        var patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
        var result = patt.exec(contentDisposition);
        var fileName = result[1];
        console.log('name', fileName)
        if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            let link = document.createElement("a");
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", false, false);
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
        }
    });
}

export function uploadFile(url, file) {
    const formFile = new FormData()
    formFile.append('file', file)
    let options = {
        url: url,
        method: 'post',
        data: formFile,
        responseType: 'blob'
    };
    let myToken = getToken();
    if (myToken) {
        axios.defaults.headers['ke-Token'] = myToken;
    }
    return new Promise((resolve, reject) => {
        // https://github.com/mzabriskie/axios

        // console.log('request', options);
        //请求处理
        return axios.request(options).then(res => {
            resolve(res);
        })
            .catch((error) => {
                reject(res)
            });
    })


}

export function pdfview(params)
{
    console.log('params', params)
    let options = {
        url: BASE_URI + '/download',
        data: params,
        method: 'post',
        responseType: 'blob'
    };

    let myToken = getToken();
    if (myToken) {
        axios.defaults.headers['Cube-Token'] = myToken;
        options.data.token =  myToken;
    }

    // options.data =  Qs.stringify(options.data);
    return new Promise((resolve, reject) => {
        // https://github.com/mzabriskie/axios

        // console.log('request', options);
        //请求处理
        return axios.request(options).then(res => {
                resolve(res);
        })
            .catch((error) => {
                reject(res)
            });
    })
}

