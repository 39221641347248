<template>
<!--    @click="clickPage"-->
    <div class="page" :id="'page'+pageIndex" >
        <posPoint v-for="item in leftList" v-show="(examNoType == 2 && pageIndex%2==0) || (examNoType == 1)" :top="item.top" :left="item.left"></posPoint>
        <posPoint v-for="item in rightList" v-show="(examNoType == 2 && pageIndex%2==1) || (examNoType == 1)" :top="item.top" :left="item.left"></posPoint>
        <div class="content" :id="'pageContent'+pageIndex">
            <pageHeader v-if="pageIndex==0" ref="pageHeader" :head="header" :title="header.title" :regNoTitle="header.regNoTitle"> </pageHeader>
            <page2Point v-if="(examNoType==1 && pageIndex==1) || (examNoType==2 && pageIndex==3)" ref="page2Point"></page2Point>
            <exam-chunk v-for="(c, i) in chunkList" :ref="'chunk-'+pageIndex" :config="c"></exam-chunk>
        </div>
        <div class="footer-no">第{{pageIndex+1}}页/共{{pageTotal}}页</div>
    </div>
</template>

<script>
    import groupItem from "./groupItem";
    import posPoint from "./posPoint";
    import pageHeader from "./pageHeader";
    import examChunk from "./components/examChunk";
    import page2Point from "./page2Point";
    import * as anwCardUtils from './anwCardUtils'
    import {mapActions, mapState} from "vuex";

    export default {
        name: "page",
        components:{
            groupItem, posPoint, pageHeader,  examChunk, page2Point
        },
        props: {
            pageIndex: {
                type: Number
            },
            pageContent: {
                type: Object
            },
        },
        computed: {
            ...mapState({
                pageConfig: state => state.card.pageConfig,
            }),
        },
        watch: {
            pageContent: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log("pageContent", n)
                    if(n){
                        if(("header" in n)){
                            this.header = n.header;
                            console.log("this.header", this.header);
                        }
                        this.chunkList = [];
                        for (let i=0; i<n.chunkList.length; i++) this.chunkList.push(Object.assign({real_time: new Date()}, n.chunkList[i]) );
                        // this.chunkList = n.chunkList.map(item =>{item["real_time"]=new Date();return item;});
                        this.pageTotal = n.pageTotal;
                    }else{
                        this.header = { title: null, papaerAb: false};
                    }
                }
            },
            pageConfig: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log("page pageConfig from ", this.examNoType, "to", n.columns);
                    if(this.examNoType != n.columns) {
                        this.examNoType = n.columns;
                    }
                }
            }
        },
        data() {
            return {
                content: '',  examNoType: 2, chunkList: [], pageTotal: 2,
                leftList: [{left: 6, top: 12, index: 1}, {left: 6, top: 52, index: 2}, {left: 6, top: 95, index: 3}, {left: 6, top: 140, index: 4}, {left: 6, top: 188, index: 5}, {left: 6, top: 240, index: 6}, {left: 6, top: 280, index: 7}],
                rightList: [{left: 198, top: 12, index: 1}, {left: 198, top: 280, index: 2}],
                point4: {leftTop: {left: 6, top: 12, width: 5, height: 5}, leftBottom: {left: 6, top: 280, width: 5, height: 5},
                    rightTop: {left: 198, top: 12, width: 5, height: 5}, rightBottom: {left: 198, top: 280, width: 5, height: 5}},
                header: {id: null, title: null, papaerAb: false, regNoTitle: null},
            }
        },
        mounted() {

        },
        methods: {
            ...mapActions('card',['setTiSelect']),
            clickPage(){
                this.setTiSelect(null);
            },
            getAnswerChildList(id){
                if(this.chunkList.length > 0){
                    let childs = [];
                    for (let i=0; i<this.chunkList.length; i++){
                        let c = this.chunkList[i];
                        if(c.type == 4){
                            let myChunk = this.$refs['chunk-'+this.pageIndex][i];
                            let list = myChunk.getAnswerChildList(id);
                            if(list != null)
                            childs = [...childs, ...list];
                        }
                    }
                    if(childs.length > 0) return childs;
                }
                return null;
            },
            getEnglishChildList(id){
                if(this.chunkList.length > 0){
                    let childs = [];
                    for (let i=0; i<this.chunkList.length; i++){
                        let c = this.chunkList[i];
                        if(c.type == 6){
                            let myChunk = this.$refs['chunk-'+this.pageIndex][i];
                            let list = myChunk.getEnglishChildList(id);
                            if(list != null)
                                childs = [...childs, ...list];
                        }
                    }
                    if(childs.length > 0) return childs;
                }
                return null;
            },
            //计算题块
            getPageArea(){
                let myPageContent = document.getElementById("pageContent" + this.pageIndex);
                let pageHeight = myPageContent.clientHeight;
                let {width, height} = anwCardUtils.getPageContentSize();
                let dpi = pageHeight / height;
                console.log("dpi", dpi);
                let pxH = 0;
                if(("pageHeader" in this.$refs)){ pxH = this.$refs.pageHeader.$el.clientHeight; }
                if(this.chunkList.length > 0){
                    let myChunk = this.$refs['chunk-'+this.pageIndex];
                    for (let i=0; i<myChunk.length; i++){
                        let c = myChunk[i];
                        pxH = pxH + c.$el.clientHeight;
                    }
                }
                return {dpi:dpi, pxHeight: pageHeight, pxH: pxH, pxCha: (pageHeight-pxH), mmH: parseInt(pxH/dpi), mmCha: parseInt((pageHeight-pxH)/dpi)};
            },
            callJson(config){
                let {width, height, dpi, index} = config;
                let {clientWidth, clientHeight, offsetLeft, offsetTop} = document.getElementById("pageContent" + this.pageIndex);
                let pageConfig = { pageWidth: clientWidth, pageHeight: clientHeight, pageLeft: offsetLeft, pageTop: offsetTop, pageIndex: this.pageIndex,
                    ptWidth: this.examNoType==1?(198-6+10):(210-6+(198+5)), ptHeight: (280-12+5), //四个角形成的长宽
                    leftTop: {left: 6, top: 12}, leftBottom: {left: 6, top: 280}, rightTop: {left: 198, top: 12}, rightBottom: {left: 198, top: 280}};
                let page = {locatePoint: this.callJsonLocation(config), pageConfig: pageConfig};
                if(index == 0 && ("pageHeader" in this.$refs)){
                    let headerTop = this.$refs.pageHeader.$el.offsetTop;
                    let pageHeader = this.$refs.pageHeader.callJson(config, pageConfig, {headerTop: headerTop});
                    console.log("page call Header json", pageHeader);
                    page["pageHeader"] = pageHeader;
                }
                console.log("callJson", this.$refs);
                if(("page2Point" in this.$refs) && this.$refs.page2Point){
                    let page2Point = this.$refs.page2Point.callJson(config, pageConfig);
                    page["noAnswerMark"] = page2Point;
                    console.log("page2Point", page2Point);
                }
                let sections = [], topicDescs = [];
                if(('chunk-'+this.pageIndex) in this.$refs){
                    let chunks = this.$refs['chunk-'+this.pageIndex];
                    for (let i=0; i<chunks.length; i++){
                        let p = chunks[i].callJson(config, pageConfig);
                        sections.push(p.p);
                        topicDescs.push(p.topicDesc);
                    }
                }
                page["sections"] = sections;
                page["topicDescs"] = topicDescs;
                console.log("topicDescs", topicDescs);
                return page;
            },
            callJsonLocation(config){
                let {index} = config;
                let locatePoint = [];
                if(this.examNoType==1){
                    let list1 = JSON.parse(JSON.stringify(this.leftList));
                    let list2 = JSON.parse(JSON.stringify(this.rightList));
                    locatePoint = [...list1.map(item =>{item["width"]=5; item["height"]=5;return item;}), ...list2.map(item =>{item["width"]=5; item["height"]=5;return item;})];
                }

                if(this.examNoType==2){
                    if(index%2 == 0){
                        let list1 = JSON.parse(JSON.stringify(this.leftList));
                        locatePoint = [...list1.map(item =>{item["width"]=5; item["height"]=5;return item;})];
                    }
                    if(index%2 == 1) {
                        let list2 = JSON.parse(JSON.stringify(this.rightList));
                        locatePoint = [...list2.map(item =>{item["width"]=5; item["height"]=5;item["left"]=210+198; return item;})];
                    }
                }
                return locatePoint;
            },
        }
    }
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">

    .page{
        position: relative;
        margin: 0 auto;
        width: 210mm;
        height: 297mm;
        background-color: white;

        .content{
            position: absolute;
            left: 15mm;
            top: 15mm;
            width: 178mm;
            height: 262mm;

        }
        .footer-no {
            position: absolute;
            text-align: left;
            left: 90mm;
            top: 287mm;
            width: 50mm;
            height: 8mm;
        }
    }

</style>
