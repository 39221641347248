<template>
    <div class="groupItem">
        <div class="title">
            {{title}}
        </div>
        <slot name="content"></slot>
        <div class="desc" v-if="desc">
            {{desc}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "groupItem",
        props: {
            title: {
                type: String
            },
            desc: {
                type: String, require:false,
            }
        },

    }
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">

    .groupItem{
        position: relative;
        border: 1px solid #ced4da;
        border-radius: 4px;
        padding: 20px 0;
        margin-bottom: 20px;
        .title{
            position: absolute;
            left: 20px;
            top: 0;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            background: #fff;
            padding: 0 4px;
            color: #000;
            font-weight: 700;
            font-size: 16px;
        }
        .desc{
            padding: 5px 15px;
            color: #666;
            font-size: 14px;
        }
    }
</style>
