<template>
    <div class="chooseOne">
        <span class="anw-index">{{seq}}</span>
        <span v-for="(c, i) in charList" ref="chse" v-if="(type==1 || type==2) && i<limit" class="option">
            <span :class="['opt-text', {'mutil': (type==2 && !isPreview)?true: false}]">[{{c}}]</span>
        </span>
        <span v-for="c in tfList" ref="chse" v-if="type==11" class="option">
            <span class="opt-text">[{{c}}]</span>
        </span>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "chooseOne",
    data(){
        return {
            charList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q'],
            tfList: ['T', 'F']
        }
    },
    computed: {
        ...mapState({
            isPreview: state => state.card.isPreview,
        }),
    },
    props: {
        seq: {
            type: Number
        },
        type: {
            type: Number
        },
        limit: {
            type: Number
        },
        count: {
            type: Number
        },
    },
    methods: {
        getChooseList(){
            if(this.type == 11) return this.tfList;
            let arr = [];
            for (let i=0; i<this.limit; i++){
                arr.push(this.charList[i]);
            }
            return arr;

        },
        callJson(config, pageConfig, tiBox, abox){
            let {dpi} = config;
            let {pageLeft, pageTop, leftTop, ptWidth, ptHeight} = pageConfig;
            let tiBoxLeft = tiBox.offsetLeft, tiBoxTop = tiBox.offsetTop, tiBoxWidth = tiBox.clientWidth, tiBoxHeight = tiBox.clientHeight;
            let {aboxLeft, aboxTop} = abox;
            let chse = this.$refs.chse;
            let lst = [];
            for (let i=0; i<chse.length; i++){
                let {clientWidth, clientHeight, offsetLeft, offsetTop} = chse[i];
                let tLeft = tiBoxLeft+aboxLeft+offsetLeft, tTop = aboxTop+offsetTop;
                let pcLeft = tLeft/tiBoxWidth;
                let pcTop = tTop/tiBoxHeight;
                let pcWidth = clientWidth/tiBoxWidth;
                let pcHeight = clientHeight/tiBoxHeight;
                // console.log("tiBox", tiBoxLeft, tiBoxTop, tiBoxHeight, "offset", chse[i], offsetLeft, offsetTop, "aboxLeft", aboxLeft,"aboxTop", aboxTop, pcLeft, "pcTop", pcTop);
                lst.push({left_begin: pcLeft, top_begin: pcTop, width_scale: pcWidth, height_scale: pcHeight });
            }
            let myChoose = {seq: this.seq, chooses: lst};
            return myChoose;
        }
    }
}
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">

.chooseOne{
    display: flex;
    flex: none;
    text-align: center;
    height: 5mm;
    .anw-index{
        font-size: 12px;
        width: 6mm;
        padding-right: 2mm;
        padding-top: 0.7mm;
        text-align: right;
        display: inline-block;
    }
    .option{
        display: block;
        flex-grow: 1;
        max-height: 5mm;
        width: 6mm;
        .opt-text {
            padding: 0 1px;
            font-size: 12px;
        }
        .mutil{
            color: red;
        }
    }
}
</style>
