<template>
    <div class="paperSheet" :style="isPreView?{}:{marginRight: '15px', marginBottom: '20px'}">
        <el-row  v-if="isPreView && pageColumns==1" v-for="(item, index) in pageList" ref="previewPage" :style="{padding: '0px', width: '210mm'}">
            <el-col :span="24"  style="padding: 0px;width: 210mm;" >
                <page :pageIndex="index" ref="page" :pageContent="item.pageContent"></page>
            </el-col>
        </el-row>
        <el-row  v-if="isPreView && pageColumns==2 && n*2-1<pageList.length" v-for="n of 2" ref="previewPage" :style="{padding: '0px', width: '420mm'}">
            <el-col :span="12" v-if="(n-1)*2<pageList.length" style="padding: 0px;width: 210mm;" >
                <page :pageIndex="(n-1)*2" ref="page" :pageContent="pageList[(n-1)*2].pageContent"></page>
            </el-col>
            <el-col :span="12" v-if="(n-1)*2+1<pageList.length" style="padding: 0px;width: 210mm;" >
                <page :pageIndex="(n-1)*2+1" ref="page" :pageContent="pageList[(n-1)*2+1].pageContent"></page>
            </el-col>
        </el-row>
        <div v-for="(item, index) in pageList" v-if="!isPreView" style="margin-bottom: 20px;">
            <page :pageIndex="index" ref="page" :pageContent="item.pageContent">
            </page>
        </div>
    </div>
</template>

<script>
    import page from "./page";
    import XEUtils from 'xe-utils'
    import { uuid } from 'vue-uuid';
    import JsPDF from 'jspdf'
    import * as simpleApi from "request/simple";
    import * as updownApi from "request/updown";
    import {mapActions, mapState} from "vuex";
    import * as anwCardUtils from './anwCardUtils'
    import {
        defaultAnswerChilds,
        getComposition100,
        getCompositionHeightPx, getFillBlankContent,
        getObject3HeightPx,
        getTiTitle, getTopicType
    } from "./anwCardUtils";
    import html2canvas from "html2canvas"
    import { saveAs } from 'file-saver';
    import uploadFile from "request/updown";

    export default {
        name: "paperSheet",
        components:{
            page
        },
        props: {
            isPreView:{
                type: Boolean, default: false
            },
            inputPage: {
                type: Object, require: false
            },
            dbObject: {
                type: Object, require: false
            },
        },
        computed: {
            ...mapState({
                tiAction: state => state.card.tiAction,
                tiChange: state => state.card.tiChange,
                pageConfig: state => state.card.pageConfig,
            }),
        },
        watch: {
            inputPage: {
                deep: true, immediate: true,
                handler(n, o) {
                    console.log("inputPage", n);
                    if(n && n.length > 0) {
                        this.pageList = n;
                    }
                }
            },
            dbObject: {
                deep: true, immediate: true,
                handler(n, o) {
                    console.log("dbObject change", n, "buildPage");
                    if(n && n != null) {
                        let o = n; //JSON.parse(JSON.stringify(n));
                        this.pageHeader.title = o.header.title;
                        this.pageHeader.row1 = o.header.row1;
                        this.pageHeader.row2 = o.header.row2;
                        this.pageHeader.notice = o.header.notice;
                        this.pageHeader.regNoTitle = o.header.regNoTitle;
                        this.allChunkList = o.chunkList;
                        this.buildPage();
                    }
                }
            },
            pageConfig: {
                deep: true, immediate: true,
                handler(n, o) {
                    console.log("pageConfig", n);
                    this.pageColumns = n.columns;
                }
            },
            tiAction: {
                deep: true, immediate: true,
                handler(n, o) {
                    console.log("tiAction", n);
                    if(n.action == 'headerTitle') {  console.log("headerTitle", n); this.pageHeader.title = n.title; this.pageHeader.name = n.text; }
                    if(n.action == 'headerRow') {this.pageList[0].pageContent.header.row1 = n.row1; this.pageList[0].pageContent.header.row2 = n.row2;
                        this.pageList[0].pageContent.header.notice = n.notice;}
                    if(n.action == 'add') this.addTi(n);
                    if(n.action == 'delete') this.deleteTi(n.id);
                    if(n.action == 'changePos') this.changeTiPos(n.id, n.dir);
                }
            },
            tiChange: {
                deep: true, immediate: true,
                handler(n, o) {
                    console.log("tiChange", n);
                    let f = XEUtils.find(this.allChunkList, item => item.id == n.id);
                    if(f) {
                        if(("count" in n) && n.count != null){
                            f.allCount = n.count; f.count = n.count;
                        }
                        if(("score" in n) && n.score != null) {f.score = n.score; this.exeSummary(1);}
                        if(("title" in n) && n.title != null)f.title = n.title;
                        if(("titleHeight" in n) && n.titleHeight > 0)f.titleHeight = n.titleHeight;
                        if(("row" in n) && n.row > 0)f.row = n.row;
                        if(("height9" in n) && n.height9 > 0)f.height9 = n.height9;
                        if(("startSeq" in n) && n.startSeq > 0)f.startSeq = n.startSeq;
                        if(("anwCount" in n) && n.anwCount > 0)f.anwCount = n.anwCount;
                        if(("fillBlank") in n && n.fillBlank != null) f.fillBlank = n.fillBlank;
                        if(("fillBlankHeight") in n && n.fillBlankHeight != null) f.fillBlankHeight = n.fillBlankHeight;
                        if(("clearFillBlank") in n ) f.fillBlank = null;
                        if(("answerChildList") in n && n.answerChildList != null) {
                            let { chList, chHeight} = this.mergeAnswerChilds(n.id);
                            f.answerChildList = chList; f.answerQuestionHeight = chHeight;
                        }
                        if(("englishChildList") in n && n.englishChildList != null) {
                            let { chList, chHeight} = this.mergeEnglishChilds(n.id);
                            f.englishChildList = chList; f.englishHeight = chHeight;
                        }
                        if(("englishRow") in n && n.englishRow > 0) {
                            f.englishChildList = anwCardUtils.defaultEnglishChilds(n.englishRow, this.dpi);
                            f.englishHeight = 4.2*(n.englishRow*2+1)*this.dpi;
                            f.row = n.englishRow;
                        }

                        this.buildPage();
                    }
                }
            },
        },
        data() {
            return {
                content: '', dpi: 0, pageHeight: 500, pageColumns: 2,
                pageHeader: { title: '', name: null, regNoTitle: null, studentNoType: 2, studentNoCount: 8, row1: null, row2: null, notice: null},
                allChunkList: [], //{id: 1, row:4, count: 4, allCount: 4, anwCount: 4, answers:[], score: 1, rows: 1 }
                pageList: [{pageContent: { id: 0, header: { title: '', name: null, }, studentNoType: 2, studentNoCount: 8, chunkList: [], pageTotal: 2 } }, {pageContent: { chunkList: [], pageTotal: 2 } }],
            }
        },
        mounted() {
            let that = this;
            this.$nextTick(()=>{
                if(!("page" in that.$refs)) return ;
                let page = that.$refs["page"][that.$refs["page"].length-1];
                let {dpi, pxHeight, pxH, pxCha, mmH, mmCha} = page.getPageArea();
                console.log("mounted", dpi, pxHeight, pxH, pxCha, mmH, mmCha);
                this.dpi = dpi;
                this.pageHeight = pxHeight;
            });
        },
        methods: {
            ...mapActions('card',['setTiSelect', 'exeSummary', 'setTiIndex', 'setIsCanSave']),
            getPageData(){
                return this.pageList;
            },
            getPageArea(){
                if(!("page" in this.$refs)) return ;
                let page = this.$refs["page"][0];
                return page.getPageArea();
            },
            getSummary(){
                let sumList = [];
                for (let i=0; i<this.allChunkList.length;i++){
                    let a = this.allChunkList[i];
                    let f = XEUtils.find(sumList, (item) => item.type==a.type);
                    let allCount = anwCardUtils.getInteger(a.allCount, 0), score = anwCardUtils.getFloat(a.score, 0);
                    if(a.type == 1 || a.type == 2 || a.type == 11 || a.type == 3){
                        if(f){ f.count = f.count + allCount; f.score = a.score; f.totalScore = f.totalScore + allCount*score;}
                        else{sumList.push({type: a.type, label: anwCardUtils.getTiTitle(a.type), count: allCount, totalScore: allCount*score, score: score})}
                    }
                    if(a.type ==4 || a.type ==5 || a.type ==6){
                        if(f){ f.count = f.count + 1; f.score = a.score; f.totalScore = f.totalScore + score;}
                        else{sumList.push({type: a.type, label: anwCardUtils.getTiTitle(a.type), count: 1, totalScore: score, score: score})}
                    }
                }
                console.log("getSummary", sumList);
                return sumList;
            },
            addTi(o){
                if(!("page" in this.$refs)) return ;
                let v = o.type;
                let chuk = {id: this.$uuid.v4(), row:o.tiRow, count: o.tiCount, allCount: o.tiCount, rows: 1, type: v, title: o.name, titleHeight: anwCardUtils.getAnwTitlePx(), anwCount: o.anwCount, startSeq: o.startSeq, score: null }
                if(v == 1 || v==2)
                    chuk = {id: this.$uuid.v4(), row:o.tiRow, count: o.tiCount, allCount: o.tiCount, rows: 1, type: v, title: o.name, titleHeight: anwCardUtils.getAnwTitlePx(), anwCount: o.anwCount, startSeq: o.startSeq, score: o.score }
                else if(v == 11)
                    chuk = {id: this.$uuid.v4(), row:o.tiRow, count: o.tiCount, allCount: o.tiCount, rows: 1, type: v, title: o.name, titleHeight: anwCardUtils.getAnwTitlePx(), anwCount: 2, startSeq: o.startSeq, score: o.score }
                else if(v == 3) //填空题
                    chuk = {id: this.$uuid.v4(), row: o.anwCount, count: o.tiCount, allCount: o.tiCount, rows: Math.ceil(o.tiCount/o.anwCount), type: v, title: o.name, titleHeight: anwCardUtils.getAnwTitlePx(),
                        fillBlank: anwCardUtils.getFillBlankContent(o.tiCount, o.anwCount, o.startSeq), anwCount: o.anwCount, fillBlankHeight:null, startSeq: o.startSeq, score: o.score }
                else if(v == 4){ //解答题
                    if(o.tiCount > 0){
                        for (let i=0; i<o.tiCount; i++){
                            let chuk1 = {id: this.$uuid.v4(), row: null, count: null, allCount: 1, rows: null, type: v, title: o.name+(o.startSeq+i), titleHeight: anwCardUtils.getAnwTitlePx(),
                                fillBlank: null, anwCount: null, answerChildList: anwCardUtils.defaultAnswerChilds(7, this.dpi), answerQuestionHeight: anwCardUtils.getObjectAnswerHeightPx(7, this.dpi), startSeq: o.startSeq, score: o.score }
                            this.allChunkList.push(chuk1);
                        }
                    }

                }else if(v == 5){
                        chuk = {id: this.$uuid.v4(), row: null, count: o.tiCount, allCount: o.tiCount, rows: Math.ceil(900/22), type: v, title: o.name, titleHeight: anwCardUtils.getAnwTitlePx(),
                            fillBlank: null, anwCount: null, compositionHeight: anwCardUtils.getCompositionHeightPx(Math.ceil(900/22), this.dpi), startSeq: o.startSeq, score: o.score }
                }else if(v == 6){
                        chuk = {id: this.$uuid.v4(), row: 7, count: null, allCount: 1, rows: 7, type: v, title: o.name, titleHeight: anwCardUtils.getAnwTitlePx(),
                            fillBlank: null, anwCount: null, englishChildList: anwCardUtils.defaultEnglishChilds(7, this.dpi), englishHeight: (4.2+9.8*7)*this.dpi, startSeq: o.startSeq, score: o.score }
                }else if(v == 9){
                    chuk = {id: this.$uuid.v4(), type: v, height9: o.height9}
                }
                if(v != 4) this.allChunkList.push(chuk);
                this.buildPage();
                this.setTiSelect(chuk);
            },
            addPage(curPage){
                if(curPage>= this.pageList.length){
                    if(this.pageColumns == 1) this.pageList.push({pageContent: { chunkList: [] } });
                    if(this.pageColumns == 2) {this.pageList.push({pageContent: { chunkList: [] } }); this.pageList.push({pageContent: { chunkList: [] } }); }
                }

            },
            deleteTi(id){
                let idx = XEUtils.findIndexOf(this.allChunkList, item => item.id == id);
                console.log("deleteTi", id, idx, this.allChunkList);
                if(idx > -1) {
                    this.allChunkList.splice(idx, 1);
                    this.buildPage();
                }
            },
            changeTiPos(id, dir){
                let idx = XEUtils.findIndexOf(this.allChunkList, item => item.id == id);
                if(dir==0 && idx > 0) {
                    let temp1 = this.allChunkList[idx-1];
                    this.allChunkList[idx-1] = this.allChunkList[idx];
                    this.allChunkList[idx] = temp1;
                    console.log('changeTiPos', this.allChunkList);
                    this.buildPage();
                }
                if(dir==1 && idx < this.allChunkList.length) {
                    let temp1 = this.allChunkList[idx+1];
                    this.allChunkList[idx+1] = this.allChunkList[idx];
                    this.allChunkList[idx] = temp1;
                    console.log('changeTiPos', this.allChunkList);
                    this.buildPage();
                }
            },
            mergeAnswerChilds(id){
                if(!("page" in this.$refs)) return ;
                let pages = this.$refs["page"];
                let chList = [], chHeight=0;
                for (let i=0; i<pages.length; i++){
                    let p = pages[i];
                    let childList = p.getAnswerChildList(id);
                    if(childList != null) chList = [...chList, ...childList];
                }
                for (let i=0; i<chList.length; i++){
                    chHeight = chHeight +chList[i].height;
                }
                console.log("mergeAnswerChilds", chList);
                return { chList: chList, chHeight: chHeight};
            },
            mergeEnglishChilds(id){
                if(!("page" in this.$refs)) return ;
                let pages = this.$refs["page"];
                let chList = [], chHeight=0;
                for (let i=0; i<pages.length; i++){
                    let p = pages[i];
                    let childList = p.getEnglishChildList(id);
                    if(childList != null) chList = [...chList, ...childList];
                }
                for (let i=0; i<chList.length; i++){
                    chHeight = chHeight +chList[i].height;
                }
                console.log("mergeEnglishChilds", chList);
                return { chList: chList, chHeight: chHeight};
            },
            buildPage(){
                console.log("buildPage", this.pageHeader.title, this.pageHeader.name)
                if(this.pageColumns == 1) this.pageList = [{pageContent: {  header: this.pageHeader, chunkList: [], pageTotal: 1 } }];
                if(this.pageColumns == 2) this.pageList = [{pageContent: {  header: this.pageHeader, chunkList: [], pageTotal: 2 } }, {pageContent: { chunkList: [], pageTotal: 2 } }];
                if(this.allChunkList.length > 0){
                    console.log("buildPage", this.allChunkList, this.pageList);
                    let curPage = 0, ixStart=1;
                    let hCha = this.pageHeight - anwCardUtils.getPageHeaderHeight() * this.dpi;
                    for (let i=0; i<this.allChunkList.length; i++){
                        let ac = this.allChunkList[i];
                        let titleHeight = (("titleHeight" in ac && ac.titleHeight > 0)?ac.titleHeight:0);
                        let chkHeight = 0;
                        if(ac.type == 1 || ac.type==2|| ac.type==11) chkHeight = anwCardUtils.getChunkHeightPx(ac.allCount, ac.row, ac.anwCount, this.dpi) + titleHeight;
                        else if(ac.type==3) {
                            let rows = ("rows" in ac)?ac.rows:2;
                            if(("anwCount" in ac)) rows = Math.ceil(ac.count/ac.anwCount);
                            if("rows" in ac) rows = ac.rows;
                            if(ac.fillBlankHeight > 0) chkHeight = ac.fillBlankHeight + titleHeight;
                            if(ac.fillBlank != null){
                                let rowArr = ac.fillBlank.split("\n");
                                rows = rowArr.length;
                                ac.fillBlankHeight = anwCardUtils.getObject3HeightPx(rows, this.dpi)+10;
                            }
                            else chkHeight = anwCardUtils.getObject3HeightPx(rows, this.dpi) + titleHeight;
                            ac.rows = rows;
                            if(ac.fillBlank == null){
                                ac.fillBlank = anwCardUtils.getFillBlankContent(ac.count, ac.anwCount, ac.startSeq);
                                ac.fillBlankHeight = anwCardUtils.getObject3HeightPx(Math.ceil(ac.count/ac.anwCount), this.dpi);
                            }
                        }
                        else if(ac.type==4) {chkHeight = ac.answerQuestionHeight + titleHeight+20; ac.rows = ac.answerChildList.length; }
                        else if(ac.type==5) {chkHeight = ac.compositionHeight + titleHeight+20; }
                        else if(ac.type==6) {chkHeight = ac.englishHeight + titleHeight+20; }
                        else if(ac.type==9) {
                            let pl = this.pageList[curPage];
                            pl.pageContent.chunkList.push(ac);
                            hCha = hCha - ac.height9*this.dpi-16;
                            continue;
                        }
                        console.log(i, 'build Page', curPage, 'type', ac.type, 'cha', hCha, 'chkHeight', chkHeight, 'titleHeight', titleHeight, "startSeq", ac.startSeq);
                        if(hCha >= chkHeight){
                            let pl = this.pageList[curPage];
                            ac["ixStart"] = ixStart;
                            pl.pageContent.chunkList.push(ac);
                            if(ac.type==4 || ac.type==5 || ac.type==6) ixStart = ixStart + 1;
                            else ixStart = ixStart + ac.allCount;
                            hCha = hCha - chkHeight;
                        }else{ //拆分
                            let a = JSON.parse(JSON.stringify(ac));
                            if(a.type == 1 || a.type==2|| a.type==11) {
                                let chaRow = parseInt((hCha-titleHeight )/ anwCardUtils.getAnwHeightPx(a.row, this.dpi));
                                if(chaRow < 1){ //如果最小行高比差额还大，则全部转向下一行
                                    curPage = curPage+1;
                                    this.addPage(curPage);
                                    let pl = this.pageList[curPage];
                                    a["ixStart"] = ixStart; ixStart = ixStart + a.allCount;
                                    pl.pageContent.chunkList.push(a);
                                    hCha = this.pageHeight - chkHeight;
                                }else{
                                    let alCount = (chaRow*a.row*anwCardUtils.maxCols(a.anwCount));
                                    let a1 = {id: a.id, row: a.row, count: alCount, allCount: a.allCount, rows: chaRow, ixStart: ixStart, startSeq: ac.startSeq, type: a.type, title: a.title, titleHeight: a.titleHeight, anwCount: a.anwCount, score: a.score };
                                    let pl = this.pageList[curPage];
                                    pl.pageContent.chunkList.push(a1);
                                    console.log('a.type', a.type, 'alCount', alCount)
                                    ixStart = ixStart + a1.count;
                                    let a2 = {id: a.id, row: a.row, count: (a.count-a1.count), allCount: a.allCount, rows: a.rows-chaRow, ixStart: ixStart, chai: ixStart, startSeq: ac.startSeq+alCount+1, type: a.type, title: null, anwCount: a.anwCount, score: a.score };
                                    ixStart = ixStart + a2.count;
                                    curPage = curPage+1;
                                    this.addPage(curPage);
                                    let pl2 = this.pageList[curPage];
                                    console.log("curPage", curPage);
                                    pl2.pageContent.chunkList.push(a2);
                                    hCha = this.pageHeight - anwCardUtils.getChunkHeightPx(a2.count, a2.row, a2.anwCount, this.dpi);
                                }
                            } else if(a.type==3) { //如果是填空题，则全部转入新的一页
                                let rows = Math.ceil(a.count/a.anwCount), a1Height=titleHeight, chaiIx=0, sheRow=0, isSplit=false, isFirst = true;
                                let rowArr = a.fillBlank.split("\n");
                                rows = rowArr.length;
                                let chaiContent = "";
                                if((a1Height + 8*this.dpi+10) > hCha){ //如果标题和第一行比差额大，则转到下一页
                                    curPage = curPage+1; this.addPage(curPage); let pl = this.pageList[curPage]; a["ixStart"] = ixStart;
                                    pl.pageContent.chunkList.push(a); ixStart = ixStart + 1; hCha = this.pageHeight - chkHeight;continue;
                                }
                                a1Height = a1Height + 10;
                                for (let j=0; j<rows; j++){
                                    a1Height = a1Height + 8*this.dpi; sheRow ++;
                                    chaiContent = chaiContent + rowArr[j]+"\n";
                                    if((a1Height + 8*this.dpi) > hCha){
                                        let a1 = {id: a.id, row: a.row, count: (j+1)*a.anwCount, allCount: a.count, rows: j+1, type: a.type, title: a.title, titleHeight: a.titleHeight, anwCount: null, answers:null, score: a.score,
                                            fillBlank: chaiContent.substr(0, chaiContent.length-1), allFillBlank: a.fillBlank, ixStart: ixStart, answerQuestion: null, answerChildList: null, fillBlankHeight: null };
                                        if(isFirst){ isFirst = false; chaiIx = ixStart; }
                                        else {a1["chai"] = chaiIx;  }
                                        ixStart = ixStart + a1.count;
                                        let pl = this.pageList[curPage];
                                        pl.pageContent.chunkList.push(a1);hCha = this.pageHeight; sheRow = 0;
                                        a1Height = 0; isSplit = true;curPage = curPage+1;this.addPage(curPage);
                                        console.log("chaiIx for", chaiIx);
                                        chaiContent = "";
                                    }
                                }
                                if(a1Height > 0 && sheRow > 0){
                                    let sheCount = a.count-(rows-sheRow)*a.anwCount;
                                    console.log("a1Height sheRow", a1Height, sheRow, sheCount);
                                    console.log("chaiIx 2222", chaiIx);
                                    let a1 = {id: a.id, row: a.row, count: sheCount, allCount: a.count, rows: sheRow, ixStart: ixStart, type: a.type, title: (!isSplit)?a.title:null,
                                        titleHeight: (!isSplit)?a.titleHeight:null, anwCount: null, chai: chaiIx, score: a.score,
                                        fillBlank: chaiContent.substr(0, chaiContent.length-1),  allFillBlank: a.fillBlank, answerQuestion: null, answerChildList: null, fillBlankHeight: null };
                                    if(isFirst){ a1["ixStart"] = ixStart; ixStart = ixStart + 1; a1.chai = null; }
                                    let pl = this.pageList[curPage];
                                    pl.pageContent.chunkList.push(a1);
                                    hCha = this.pageHeight - a1Height;
                                }
                            }else if(a.type == 4){
                                let childList = a.answerChildList, a1Childs="", a1Height=titleHeight, chaiIx=0, answerChildList=[], isSplit=false, isFirst = true;
                                console.log("解答题拆分", a1Height, hCha, a);
                                if((a1Height + childList[0].height+10) > hCha){ //如果标题和第一行比差额大，则转到下一页
                                    curPage = curPage+1; this.addPage(curPage); let pl = this.pageList[curPage]; a["ixStart"] = ixStart;
                                    pl.pageContent.chunkList.push(a); ixStart = ixStart + 1; hCha = this.pageHeight - chkHeight;continue;
                                }
                                console.log("解答题拆分2", childList);
                                for (let j=0; j<childList.length; j++){
                                    let cid = childList[j];
                                    a1Height = a1Height + cid.height;
                                    a1Childs = a1Childs + cid.html;
                                    answerChildList.push(cid);
                                    console.log("解答题拆分3", a1Height, cid);
                                    if((a1Height + cid.height) > hCha){
                                        // console.log('anwser >>>', a1Childs, a1Height, hCha);
                                        console.log('解答题拆分'+j, a1Childs, a1Height, hCha);
                                        let a1 = {id: a.id, row: null, count: null, allCount: null, rows: childList.length, type: a.type, title: a.title, titleHeight: a.titleHeight, anwCount: null, answers:null, score: a.score,
                                            fillBlank: null, answerQuestion: a1Childs, answerChildList: answerChildList, answerQuestionHeight: a1Height };
                                        if(isFirst){ chaiIx = ixStart; a1["ixStart"] = ixStart; ixStart = ixStart + 1; isFirst = false; console.log('isFirst', j, a1Height, cid.height, hCha);}
                                        else {a1["chai"] = chaiIx; a1["ixStart"] = ixStart-1; }
                                        let pl = this.pageList[curPage];
                                        pl.pageContent.chunkList.push(a1);
                                        hCha = this.pageHeight;
                                        a1Height = 0; answerChildList = []; isSplit = true;
                                        a1Childs = "";
                                        curPage = curPage+1;
                                        this.addPage(curPage);
                                    }
                                }
                                if(a1Height > 0){
                                    let a1 = {id: a.id, row: null, count: null, allCount: null, rows: childList.length, ixStart: ixStart-1, type: a.type, title: (!isSplit)?a.title:null,
                                        titleHeight: (!isSplit)?a.titleHeight:null, anwCount: null, chai: chaiIx, score: a.score,
                                        fillBlank: null, answerQuestion: a1Childs, answerChildList: answerChildList, answerQuestionHeight: a1Height };
                                    console.log('解答题拆分last', a1)
                                    if(isFirst){ a1["ixStart"] = ixStart; ixStart = ixStart + 1; a1.chai = null; }
                                    let pl = this.pageList[curPage];
                                    pl.pageContent.chunkList.push(a1);
                                    hCha = this.pageHeight - a1Height;
                                }
                            }else if(a.type == 5){ //作文
                                let rows = Math.ceil(a.allCount/22), a1Height=titleHeight+20, chaiIx=0, start=1, lineH=9.5*this.dpi, a1Row=0, idxRow=0, isSplit=false, isFirst = true;
                                console.log('composition111 >>>', rows, lineH, a1Height, hCha, curPage);
                                if((a1Height + lineH) > hCha){ //如果标题和第一行比差额大，则转到下一页
                                    curPage = curPage+1; this.addPage(curPage); hCha = this.pageHeight;
                                }
                                for (let j=0; j<rows; j++){
                                    a1Height = a1Height + lineH; a1Row++; idxRow++;
                                    // console.log('>>>', j, a1Row, a1Height, hCha);
                                    if((a1Height+lineH) > hCha){
                                        console.log('composition >>>', rows, a1Row, a1Height, hCha, curPage);
                                        let a1 = {id: a.id, row: a1Row, count: a.allCount, allCount: a.allCount, rows: a1Row, type: a.type, title: a.title, titleHeight: a.titleHeight,
                                            anwCount: null, answers:null, score: a.score, start: start, f100: anwCardUtils.getComposition100(a1Row, start)};
                                        if(isFirst){ chaiIx = ixStart; a1["ixStart"] = ixStart; ixStart = ixStart + 1; isFirst = false; }
                                        else{ a1["chai"] = chaiIx; a1["ixStart"] = ixStart-1; }
                                        let pl = this.pageList[curPage];
                                        pl.pageContent.chunkList.push(a1); isSplit = true;
                                        hCha = this.pageHeight;
                                        start = (22*idxRow)+1; a1Height = 20; a1Row = 0;
                                        curPage = curPage+1;
                                        this.addPage(curPage);
                                    }
                                }
                                if(a1Row > 0){
                                    // console.log('end', a1Row, a1Height, hCha, start, curPage);
                                    let a1 = {id: a.id, row: a1Row, count: a.allCount, allCount: a.allCount, rows: a1Row, ixStart: ixStart-1,  type: a.type, title: (!isSplit)?a.title:null,
                                        titleHeight: (!isSplit)?a.titleHeight:null, anwCount: null,
                                        chai: chaiIx, score: a.score, f100: anwCardUtils.getComposition100(a1Row, start), start: start};
                                    if(isFirst){ a1["ixStart"] = ixStart; ixStart = ixStart + 1; a1.chai = null; }
                                    let pl = this.pageList[curPage];
                                    pl.pageContent.chunkList.push(a1);
                                    hCha = this.pageHeight - a1Height;
                                }
                            }else if(a.type == 6){
                                let childList = a.englishChildList, a1Childs="", a1Height=titleHeight, chaiIx=0, englishChildList=[], isSplit=false, isFirst = true;
                                console.log("6 english hCha", hCha, 'a1Height', a1Height, 'first height', childList[0].height)
                                if((a1Height + childList[0].height) > hCha){ //如果标题和第一行比差额大，则转到下一页
                                    console.log("6 english a1Height + childList[0].height) > hCha", curPage);
                                    curPage = curPage+1; this.addPage(curPage); let pl = this.pageList[curPage]; a["ixStart"] = ixStart;
                                    pl.pageContent.chunkList.push(a); ixStart = ixStart + 1; hCha = this.pageHeight - chkHeight;continue;
                                }
                                for (let j=0; j<childList.length; j++){
                                    let cid = childList[j];
                                    a1Height = a1Height + cid.height;
                                    a1Childs = a1Childs + cid.html;
                                    englishChildList.push(cid);
                                    // console.log('english', j, a1Height, cid.height, hCha);
                                    if(a1Height + cid.height > hCha){
                                        // console.log('english >>>', a1Height, hCha, curPage);
                                        let a1 = {id: a.id, row: null, count: null, allCount: null, rows: null, type: a.type, title: a.title, titleHeight: a.titleHeight, anwCount: null, answers:null, score: a.score,
                                            fillBlank: null, englishChildList: englishChildList, englishHeight: a1Height };
                                        if(isFirst) { chaiIx = ixStart; a1["ixStart"] = ixStart; ixStart = ixStart + 1; isFirst = false; }
                                        else {a1["chai"] = chaiIx; a1["ixStart"] = ixStart-1;}
                                        let pl = this.pageList[curPage];
                                        pl.pageContent.chunkList.push(a1);
                                        hCha = this.pageHeight;
                                        a1Height = 0; englishChildList = []; isSplit = true;
                                        a1Childs = "";
                                        curPage = curPage+1;
                                        this.addPage(curPage);
                                    }
                                }
                                if(a1Height > 0){
                                    let a1 = {id: a.id, row: null, count: null, allCount: null, rows: null, ixStart: ixStart-1, type: a.type, title: (!isSplit)?a.title:null,
                                        titleHeight: (!isSplit)?a.titleHeight:null, anwCount: null, chai: 10, score: a.score,
                                        fillBlank: null, englishChildList: englishChildList, englishHeight: a1Height };
                                    if(isFirst){ a1["ixStart"] = ixStart; ixStart = ixStart + 1; a1.chai = null; }
                                    let pl = this.pageList[curPage];
                                    pl.pageContent.chunkList.push(a1);
                                    hCha = this.pageHeight - a1Height;
                                }

                            }
                        }
                    }
                    this.pageList.map(item=>{ item.pageContent["pageTotal"] = this.pageList.length; })
                    this.exeSummary(1);
                    if(this.pageConfig.columns == 1 && this.pageList.length > 2){this.setIsCanSave(false); return ;}
                    if(this.pageConfig.columns == 2 && this.pageList.length > 4){this.setIsCanSave(false);return ;}
                    this.setIsCanSave(true);
                }
            },
            callJson(){
                let sht = {}, pageSheets = [], paper = {}, topicDescs = [];
                if(this.pageColumns == 1){
                    for (let i=0; i<this.$refs["page"].length; i++){
                        let pg = this.$refs["page"][i];
                        let {clientWidth, clientHeight }= pg.$el;
                        let page1 = pg.callJson({width: clientWidth, height: clientHeight, dpi: this.dpi, index: i});
                        console.log("page1", page1);
                        let page = {locatePoint: page1.locatePoint, sections: page1.sections};
                        if(("pageHeader" in page1)) {
                            page["row1"] = page1.pageHeader.row1;
                            page["row2"] = page1.pageHeader.row2;
                            page["notice"] = page1.pageHeader.notice;
                            paper["paperName"] = { name: page1.pageHeader.paperName, position: {top: 0, left: 0, width: 180, height:20  } };
                            page["ticketNum"] = page1.pageHeader.ticketNum;
                            if("paperAB" in page1.pageHeader){
                                paper["paperAB"] =page1.pageHeader.paperAB;
                            }
                        }
                        if(("noAnswerMark" in page1)) {
                            paper["noAnswerMark"] = { position: page1.noAnswerMark };
                        }
                        if(("topicDescs" in page1) && page1.topicDescs.length > 0) {
                            topicDescs = [...topicDescs, ...page1.topicDescs];
                        }
                        console.log("page", i, page);
                        pageSheets.push(page);
                    }
                }
                if(this.pageColumns == 2){
                    for (let i=0; i<this.$refs["page"].length; i=i+2){
                        let pg1 = this.$refs["page"][i];
                        let {clientWidth, clientHeight }= pg1.$el;
                        let page1 = pg1.callJson({width: clientWidth, height: clientHeight, dpi: this.dpi, index: i});
                        console.log("page1", page1);
                        let pg2 = this.$refs["page"][i+1];
                        let clientWidth2 = pg2.$el.clientWidth;
                        let clientHeight2 = pg2.$el.clientHeight;
                        let page2 = pg2.callJson({width: clientWidth2, height: clientHeight2, dpi: this.dpi, index: i+1});
                        let page = {locatePoint: [...page1.locatePoint, ...page2.locatePoint], sections: [...page1.sections, ...page2.sections]};
                        if(("pageHeader" in page1)) {
                            paper["paperName"] = { name: page1.pageHeader.paperName, position: {top: 0, left: 0, width: 180, height:20  },
                                row1: page1.pageHeader.row1, row2: page1.pageHeader.row2, notice: page1.pageHeader.notice};
                            page["ticketNum"] = page1.pageHeader.ticketNum;
                        }
                        if(("noAnswerMark" in page2)) {
                            console.log("noAnswerMark", page2);
                            paper["noAnswerMark"] = { position: page2.noAnswerMark };
                        }
                        if(("topicDescs" in page1) && page1.topicDescs.length > 0) {
                            topicDescs = [...topicDescs, ...page1.topicDescs];
                        }
                        if(("topicDescs" in page2) && page2.topicDescs.length > 0) {
                            topicDescs = [...topicDescs, ...page2.topicDescs];
                        }
                        console.log("page", page);
                        pageSheets.push(page);
                    }
                }
                paper["pageSheets"] = pageSheets;
                let topicColl = {missScore: 0, topicTypeDescs: []}, myTopics = [];
                console.log("pagesheet", topicDescs);
                for (let i=0; i<topicDescs.length; i++){
                    let a = topicDescs[i];
                    let myNumList = a.numList;
                    if(a.topicType == 'AnswerQuestion' || a.topicType == 'Composition' || a.topicType == 'EnglishComposition') myNumList = a.ixList;
                    myTopics.push({topicDescs: [{missScore: 0, noList: myNumList, indexList: a.ixList, topicType: a.topicType, index: a.index, optionCount: a.optionCount, content: ""}]});
                }
                topicColl.topicTypeDescs = myTopics;
                console.log("paper", paper);
                return {paper: paper, topicColl: topicColl};
            },
            saveImages(flag, id,  callback){
                if(this.isPreView){
                    let mySheet = this.$refs.previewPage;
                    let that = this;
                    console.log("saveImages", flag, mySheet.length);
                    if(mySheet.length == 1){
                        this.toImage(mySheet[0].$el, flag,function (oImg){
                            that.savePdf({image1: oImg.image, width1: oImg.width, height1: oImg.height, id: id, flag: flag}, function () {
                                if(callback) callback();
                            });
                        })
                    }
                    if(mySheet.length == 2){
                        this.toImage(mySheet[0].$el, flag,function (oImg){
                            let p = {image1: oImg.image, width1: oImg.width, height1: oImg.height, image2: null, id: id, flag: flag};
                            that.toImage(mySheet[1].$el, flag,function (oImg2){
                                p["image2"] = oImg2.image;
                                p["width2"] = oImg2.width;
                                p["height2"] = oImg2.height;
                                that.savePdf(p, function () {
                                    if(callback) callback();
                                });
                            })

                        })
                    }
                }
            },
            toImage(div, flag, callback){
                let headTitle = this.pageList[0].pageContent.header.name;
                html2canvas(div, {dpi: window.devicePixelRatio*2,
                    scale: 2,}).then(canvas => {
                    let img1 = canvas.toDataURL("image/png");
                    let oImg = {width: div.clientWidth, height: div.clientHeight, image: img1};
                    // console.log("saveImages", img1);
                    if(flag == 1){
                        if (img1 !== "") {
                            let downName = (headTitle!=null && headTitle!='')?(headTitle+".png"):"preview"+".png";
                            saveAs(img1, downName);
                            if(callback) callback(oImg);
                        }else{
                            if(callback) callback(oImg);
                        }
                    }else{
                        if(callback) callback(oImg);
                    }

                });
            },

            savePdfToUCloud(para, callback){
                let that = this;
                let pageType = 'a4';
                if(this.pageColumns == 2) pageType = 'a3';
                console.log("pagetype", pageType);
                let pdf = new JsPDF('l', 'pt', pageType, true);
                let pdfWidth = pdf.internal.pageSize.getWidth();
                let pdfHeight = pdf.internal.pageSize.getHeight();
                let pageHeight = para.width1 / pdfWidth * pdfHeight;
                let leftHeight = para.height1;
                let position = 0;
                let imgWidth = pdfWidth;
                let imgHeight = pdfWidth/para.width1 * para.height1;
                console.log("image 1, w, h", para.width1, para.height1, pdfWidth, pdfHeight);
                if (leftHeight < pageHeight) {
                    pdf.addImage(para.image1, 'JPEG', 0, 0, imgWidth, imgHeight , '', 'FAST');
                } else {
                    while(leftHeight > 0) {
                        pdf.addImage(para.image1, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST')
                        leftHeight -= pageHeight;
                        position -= 841.89;
                        //避免添加空白页
                        // if(leftHeight > 0) {
                        //     pdf.addPage();
                        // }
                    }
                }

                if("image2" in para){
                    pdf.addPage();
                    console.log("image 2, w, h", para.width2, para.height2);
                    let pageHeight = para.width2 / pdfWidth * pdfHeight;
                    let leftHeight = para.height2;
                    let position = 0;
                    let imgWidth = pdfWidth;
                    let imgHeight = pdfWidth/para.width2 * para.height2;
                    if (leftHeight < pageHeight) {
                        pdf.addImage(para.image2, 'JPEG', 0, 0, imgWidth, imgHeight, '', 'FAST' );
                    } else {
                        while(leftHeight > 0) {
                            pdf.addImage(para.image2, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST')
                            leftHeight -= pageHeight;
                            position -= 841.89;
                            //避免添加空白页
                            // if(leftHeight > 0) {
                            //     pdf.addPage();
                            // }
                        }
                    }
                }

                let dataBlob = pdf.output('blob');
                let pdfFile = new File([dataBlob], para.id+ ".pdf");
                console.log("pdfUrl", pdfFile);
                updownApi.uploadFile('exam/scantron/uploadPdf?id='+para.id, pdfFile).then(res =>  {
                    console.log(res)
                    if(para.flag == 4){
                        let headTitle = that.pageList[0].pageContent.header.name || that.pageList[0].pageContent.header.title;
                        pdf.save(headTitle + '.pdf');
                    }
                });
                // ucludUtil.putFile(pdfFile, '/scantron/' + para.id+ ".pdf");
                // this.uploadToUCloud(pdfUrl, para.id);
                if(callback) callback();
            },
            uploadToUCloud(fileUrl, id){

            },
            savePdf(para, callback){
                let flag = para.flag;
                if(flag == 3 || flag == 4) {
                    this.savePdfToUCloud(para, function () {
                        if (callback) callback();
                    });
                    return ;
                }
                let param = { controllerName: 'exam/scantron', methodName: '/savePdf', param: para};
                simpleApi.post(param).then(({ result: { code, data}}) =>  {
                    console.log(data)
                    if(flag == 2) {
                        console.log("savePdf name", this.pageList[0].pageContent);
                        let headTitle = this.pageList[0].pageContent.header.name || this.pageList[0].pageContent.header.title;
                        let downName = (headTitle != null && headTitle != '') ? (headTitle + ".pdf") : "preview" + ".pdf";
                        saveAs(data, downName);
                        if (callback) callback();
                    }else{
                        if(callback) callback();
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
            }
        }
    }
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">

    .paperSheet{


    }

</style>
