<template>
    <div class="examChunk" ref="examChunk">
<!--         @onBlur="editorChange" :style="{height: titleHeight+'px'}"-->
<!--        <span v-if="showTitle &&(tiType==3||tiType==4||tiType==5||tiType==6)" style="float: left;">{{myChunk.startSeq}}.</span>-->
        <editor v-model="editorData" ref="editor" v-if="showTitle" :init="editorInit" @onChange="editorChange" @onBlur="editorBlur" class="ti-title" ></editor>
        <div class="ti-list" :style="myStyle" ref="tiBox" @click.stop="doActive" v-if="tiType==1 ||tiType==2||tiType==11">
            <answerBox v-for="a in anwList" ref="answerBox" :count="a.count" :type="tiType" :start="a.start" :end="a.end"
                       :limit="a.anwCount" :colWidth="a.width" :left="a.left" :top="a.top"></answerBox>
        </div>

<!--        <editor v-model="myChunk.fillBlank" ref="fillBlankEditor" v-if="tiType==3" :init="editorInit" @onChange="fillBlankChange" @onBlur="fillBlankBlur" @onFocus="doActive" class="fill-blank"-->
<!--                :style="(fillBlankActive && !isPreview)?{border: '1px solid red'}:{border: '1px solid black'}"></editor>-->
        <div v-if="tiType==3" class="fill-blank-desc" ref="fillBlankDiv"
             :style="(fillBlankActive && !isPreview)?{border: '1px solid red'}:{border: '1px solid black'}">
            <div v-html="fillBlankHtml()" v-if="isPreview" class="preview"></div>
            <el-input v-model="myChunk.fillBlank" v-else type="textarea" :rows="myChunk.rows" resize="none" @focus="doActive" @input="fillBlankChange" @blur="fillBlankBlur2"
            > </el-input>
<!--                      :style="{height: myChunk.fillBlankHeight>0?(myChunk.fillBlankHeight+'px'): 'auto'}"-->
        </div>

        <editor v-model="myChunk.answerQuestion" ref="answerQuestion" v-if="tiType==4" :init="editorInit"  @onBlur="answerQuestionBlur" @onFocus="doActive" class="answer-question"
                :style="{border: (fillBlankActive && !isPreview)?'1px solid red':'1px solid black', padding: '10px 0px 0px 0px'}"></editor>

        <div v-if="tiType==5" class="composition" ref="composition" :style="{border: (fillBlankActive)?'1px solid red':'1px solid black'}" @click.stop="doActive">
            <compositionRow v-for="n of myChunk.rows" :f100="myChunk.f100" :start="myChunk.start+(n-1)*22" :topLine="n==1?true:false" :bottomLine="n>0?true:false"></compositionRow>
        </div>

        <editor v-model="myChunk.english" ref="englishEditor" v-if="tiType==6" :init="editorInit" @onBlur="englishBlur" @onFocus="doActive" class="fill-blank"
                :style="(fillBlankActive && !isPreview)?{border: '1px solid red'}:{border: '1px solid black'}"></editor>

        <div v-if="tiType==9" class="empty-block" ref="emptyBlock" @click.stop="doActive" :style="blockStyle">
        </div>
    </div>
</template>

<script>
    import tinymce from 'tinymce/tinymce'
    import Editor from '@tinymce/tinymce-vue'
    import 'tinymce/themes/silver/theme'
    import 'tinymce/icons/default/icons.min.js'
    import 'tinymce/plugins/image'
    import 'tinymce/plugins/link'
    import 'tinymce/plugins/code'
    import 'tinymce/plugins/table'
    import 'tinymce/plugins/lists'
    import 'tinymce/plugins/contextmenu'
    import 'tinymce/plugins/wordcount'
    import 'tinymce/plugins/colorpicker'
    import 'tinymce/plugins/textcolor'
    import {mapActions, mapState} from "vuex";
    import answerBox from "./answerBox";
    import compositionRow from "./compositionRow";
    import * as anwCardUtils from "../anwCardUtils";
    import {uuid} from "vue-uuid";

    export default {
        name: "examChunk",
        components:{
            'editor': Editor, answerBox, compositionRow,
        },
        props: {
            config: {
                type: Object
            },
        },
        computed: {
            ...mapState({
                pageConfig: state => state.card.pageConfig,
                tiSelect: state => state.card.tiSelect,
                tiIndex: state => state.card.tiIndex,
                isPreview: state => state.card.isPreview,
            }),
        },
        watch: {
            config: {
                deep: true, immediate:true,
                handler(n, o){
                    if(n){
                        console.log("config", n.ixStart, n);
                        this.id = n.id;
                        if(("title" in n) && n.title != null){
                            this.showTitle = true;
                            if(("titleHeight" in n)) {
                                this.titleHeight = n.titleHeight;
                                console.log("chunk titleHeight", n.titleHeight);
                            }
                            if(n.title != null && n.title.indexOf("font-size") < 0 && n.title.indexOf("<span") < 0){
                                let s = '<p style="text-align: left;" data-mce-style="text-align: left;"><span style="font-size: 10pt;" data-mce-style="font-size: 10pt;">'+n.title+'</span></p>';
                                this.editorData = s;
                            }else{
                                this.editorData = n.title;
                            }
                        }else{
                            this.showTitle = false;
                        }
                        if(("chai" in n) && n.chai > 0){
                            this.showTitle = false;
                        }
                        this.tiType = n.type;
                        this.myChunk = {id: n.id, row: n.row, count: n.count, allCount: n.allCount, rows: n.rows, startIndex:("startIndex" in n)?n.startIndex: null,
                            startSeq:("startSeq" in n)?n.startSeq: null, ixStart: n.ixStart, type: n.type, title: n.title, anwCount: n.anwCount,
                            chai: n.chai, score: n.score, fillBlank:n.fillBlank, fillBlankHeight:n.fillBlankHeight, answerChildList:n.answerChildList,
                            answerQuestionHeight: n.answerQuestionHeight, answerQuestionChaHeiht: n.answerQuestionChaHeiht,
                            englishChildList: n.englishChildList,};
                        if(n.type == 1 || n.type == 2 || n.type == 11) this.calcAnswerBox(this.myChunk);
                        if(n.type == 3){
                            if(n.startSeq == null){
                                this.myChunk["startSeq"] = n.ixStart;
                            }
                            if("allFillBlank" in n){
                                this.myChunk["allFillBlank"] = n.allFillBlank;
                            }
                            this.oldFillBankText = n.fillBlank;
                            console.log("chuck fillblank", n.rows)
                        }
                        if(n.type == 4 && n.answerChildList != null){
                            let s = "";
                            for (let i=0; i<n.answerChildList.length; i++) s = s + n.answerChildList[i].html;
                            this.myChunk["answerQuestion"] = s;
                            this.myChunk.answerChildList = n.answerChildList;
                            if(n.startSeq == null) { this.myChunk["startSeq"] = n.ixStart }
                        }
                        if(n.type == 5){
                            this.myChunk["start"] = n.start;
                            this.myChunk["f100"] = n.f100;
                            if(n.startSeq == null) { this.myChunk["startSeq"] = n.ixStart }
                            console.log('composition', n.start, n.f100);
                        }
                        if(n.type == 6 && n.englishChildList != null){
                            let s = "";
                            for (let i=0; i<n.englishChildList.length; i++) s = s + n.englishChildList[i].html;
                            this.myChunk["english"] = s;
                            if(n.startSeq == null) { this.myChunk["startSeq"] = n.ixStart}
                        }
                        if(n.type == 9){
                            console.log("empty block ", this.myChunk, n.height9);
                            this.myChunk["height9"] = n.height9;
                            this.blockStyle.height = n.height9 + 'mm';
                        }
                    }
                }
            },
            tiSelect:{
                deep: true, immediate:true,
                handler(n, o){
                    if(n){
                        this.myStyle.border = '1px solid black';
                        this.fillBlankActive = false;
                        this.blockStyle.border = 'None';
                        if(n.type == 9){
                            if(this.id==n.id){
                                if(this.isPreview) {
                                    this.blockStyle.border = 'None';
                                }else{
                                    this.blockStyle.border = '1px solid red';
                                }
                            }else{
                                this.blockStyle.border = '1px solid black';
                            }
                        }else{
                            if(this.id == n.id && (!this.isPreview)){
                                this.myStyle.border = '1px solid red';
                                this.fillBlankActive = true;
                            }else{
                                this.myStyle.border = '1px solid black';
                                this.fillBlankActive = false;
                            }
                        }
                    }

                }
            },


        },
        data(){
            return {
                id: null,  editor: null, editorData: null, showTitle: true, tiType: 1, anwCount: 4, tiListHeight: 10, titleHeight: 22,
                myChunk: {id: null, row: null, count: null, allCount: null, rows: null, startSeq: null, type: null, title: null, anwCount: null,
                    answers:null, score: null, fillBlank: null, answerQuestion: null, answerChildList: null,
                    answerQuestionHeight: null , fillBlankHeight: null, answerQuestionChaHeiht: null, english: null, englishChildList: null,},
                oldFillBankText: null,
                fillBlankActive: false, isChange: false,
                anwList: [], myStyle: {height: '2mm', border: '1px solid black'},
                blockStyle: {height: '5mm', border: '1px solid black'},
                editorInit: {
                    language_url: 'tinymce/langs/zh_CN.js',
                    language: 'zh_CN',
                    // content_css: "tinymce/css/fillBlankContent.css",
                    skin_url: '/tinymce/skins/ui/oxide',
                    fontsize_formats: "10pt 12pt 14pt 18pt 24pt 36pt",
                    plugins: 'link lists image code',
                    toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | undo redo',
                    branding: false,
                    menubar: false, inline: true,

                },

            }
        },
        mounted() {
        },
        methods: {
            ...mapActions('card',['setTiSelect', 'setTiChange', 'setTiIndex']),
            doActive(){
                console.log("doActive", this.myChunk);
                this.setTiSelect(this.myChunk);
            },
            setTitle(v){
                if(this.showTitle){
                    let s = '<p style="text-align: left;" data-mce-style="text-align: left;"><span style="font-size: 10pt;" data-mce-style="font-size: 10pt;">'+v+'</span></p>';
                    this.editorData = s;
                }
            },
            editorChange(v){
                // console.log("editorChange2", this.$refs.editor);
                let editorHeight = this.$refs.editor.$el.clientHeight;
                // this.$refs.editor.editor.selection.getContent({ format: 'text' })
                console.log("editorHeight change", this.$refs.editor.editor.getBody().textContent);
                this.titleHeight = editorHeight;

            },
            editorBlur(){
                console.log("editor Blur", this.titleHeight);
                this.setTiChange({id: this.id, title: this.editorData, titleHeight: this.titleHeight });
            },
            fillBlankHtml(){
                let fillBlank = this.myChunk.fillBlank;
                fillBlank= fillBlank.replace(/\n/g, "<br>");
                fillBlank= fillBlank.replace(/ /g, "&nbsp;");
                return fillBlank;
            },
            fillBlankChange(){
                this.isChange = true;
                let fillBlank = this.myChunk.fillBlank;
                let lines = fillBlank.split("\n");
                console.log("lines", lines);
                this.myChunk.rows = lines.length;
            },
            fillBlankBlur(){
                let editorHeight = this.$refs.fillBlankEditor.$el.clientHeight;
                this.setTiChange({id: this.id, fillBlankHeight: editorHeight});
            },

            fillBlankBlur2(){
                let fillBlank = this.myChunk.fillBlank;
                let lines = fillBlank.split("\n");
                this.myChunk.rows = lines.length;
                if("chai" in this.myChunk && "allFillBlank" in this.myChunk){
                    fillBlank = this.myChunk.allFillBlank.replace(this.oldFillBankText, fillBlank);
                }
                if(this.isChange){
                    this.setTiChange({id: this.id, fillBlank: fillBlank});
                }
            },
            getAnswerChildList(id){
                if(this.myChunk.type == 4 && this.myChunk.id == id){
                    let childNodes = this.$refs.answerQuestion.$el.childNodes;
                    let childList = [];
                    for (let i=0; i<childNodes.length; i++){
                        let child = childNodes[i];
                        childList.push({html: child.outerHTML, height: child.clientHeight});
                    }
                    return childList;
                }
                return null;
            },
            getEnglishChildList(id){
                if(this.myChunk.type == 6 && this.myChunk.id == id){
                    let childNodes = this.$refs.englishEditor.$el.childNodes;
                    let childList = [];
                    for (let i=0; i<childNodes.length; i++){
                        let child = childNodes[i];
                        childList.push({html: child.outerHTML, height: child.clientHeight});
                    }
                    return childList;
                }
                return null;
            },
            answerQuestionBlur(){
                let editorHeight = this.$refs.answerQuestion.$el.clientHeight;
                console.log("answerQuestionBlur", this.$refs.answerQuestion, editorHeight);
                let childNodes = this.$refs.answerQuestion.$el.childNodes;
                let childList = [];
                for (let i=0; i<childNodes.length; i++){
                    let child = childNodes[i];
                    childList.push({html: child.outerHTML, height: child.clientHeight});
                }
                this.myChunk.answerChildList = childList;
                this.setTiChange({id: this.id, answerChildList: childList, startSeq: this.myChunk.startSeq, answerQuestionHeight: editorHeight});
            },
            englishBlur(){
                let editorHeight = this.$refs.englishEditor.$el.clientHeight;
                let childNodes = this.$refs.englishEditor.$el.childNodes;
                let childList = [];
                for (let i=0; i<childNodes.length; i++){
                    let child = childNodes[i];
                    childList.push({html: child.outerHTML, height: child.clientHeight});
                }
                console.log("englishBlur", this.$refs.englishEditor, editorHeight, childList);
                this.myChunk.englishChildList = childList;
                this.setTiChange({id: this.id, englishChildList: childList, englishHeight: editorHeight});
            },
            calcAnswerBox({row, count, anwCount, startSeq, ixStart}){

                let maxCols = anwCardUtils.maxCols(anwCount);
                this.anwList = [];
                let w = anwCardUtils.getColWidth(anwCount);
                let h = anwCardUtils.getAnwHeight(row);
                let myTop = 0, myLeft = 0, iCount = 0, isBreak=false;
                let rows = Math.ceil(Math.ceil(count / row) / maxCols);
                let iStartSeq = startSeq?startSeq:ixStart;
                for (let i=0; i<rows; i++){
                    if(i>0) myTop = myTop + h;
                    for (let j=0; j<maxCols; j++){
                        if(j==0) myLeft = 3;
                        if(j>0) myLeft = myLeft + w + 3;
                        if((iCount + row) >=count) {
                            let rowCou = count - iCount;
                            let p = {count: rowCou, anwCount: anwCount, start: (iCount+iStartSeq), end: (count+iStartSeq), width: w, left: myLeft, top: myTop};
                            this.anwList.push(p);
                            isBreak = true; break;
                        }
                        let p = {count: row, anwCount: anwCount, start: (iCount+iStartSeq), end: (iCount+row+iStartSeq), width: w, left: myLeft, top: myTop};
                        this.anwList.push(p);
                        iCount = iCount + row;
                    }
                    if(isBreak) break;
                }
                this.tiListHeight = anwCardUtils.getChunkHeight(count, row, anwCount);
                this.myStyle.height = this.tiListHeight + 'mm';
                console.log("this.anwList", this.tiListHeight, this.anwList );
            },
            callJson(config, pageConfig){
                console.log("chunk", config, pageConfig);
                let {dpi} = config;
                let {pageLeft, pageTop, leftTop, ptWidth, ptHeight, pageIndex} = pageConfig;
                let pageWidth = 0, numList = [], ixList = [];
                if(this.pageConfig.columns==2 && pageIndex%2==1) pageWidth = 210;
                let examChunk = this.$refs.examChunk;
                let p = {score: this.myChunk.score}, tiBox = null, optionCount = 0;
                if(this.tiType==1 || this.tiType==2 || this.tiType==11){
                    tiBox = this.$refs.tiBox;
                    p["type"] = "Object";
                }
                if(this.tiType == 3){
                    tiBox = this.$refs.fillBlankDiv;
                    p["type"] = "FillBlank";
                }
                if(this.tiType == 4){
                    tiBox = this.$refs.answerQuestion.$el;
                    p["type"] = "AnswerQuestion";
                }
                if(this.tiType == 5){
                    tiBox = this.$refs.composition;
                    p["type"] = "Composition";
                }
                if(this.tiType == 6){
                    tiBox = this.$refs.englishEditor.$el;
                    p["type"] = "EnglishComposition";
                }
                if(this.tiType == 9){
                    tiBox = this.$refs.emptyBlock;
                    p["type"] = "emptyBlock";
                }
                let tiBoxPosition = { width: tiBox.clientWidth/dpi, height: tiBox.clientHeight/dpi, left: (pageLeft + examChunk.offsetLeft + tiBox.offsetLeft)/dpi-leftTop.left+pageWidth,
                    clientWidth: tiBox.clientWidth, clientHeight: tiBox.clientHeight, offsetLeft: tiBox.offsetLeft, offsetTop: tiBox.offsetTop,
                    top: (examChunk.offsetTop + tiBox.offsetTop)/dpi+15-12 };
                tiBoxPosition["left_begin"] = tiBoxPosition.left / ptWidth;
                tiBoxPosition["top_begin"] = tiBoxPosition.top / ptHeight;
                tiBoxPosition["left_end"] = (tiBoxPosition.left+tiBoxPosition.width) / ptWidth;
                tiBoxPosition["top_end"] = (tiBoxPosition.top+tiBoxPosition.height) / ptHeight;
                tiBoxPosition["width_scale"] = tiBoxPosition.width / ptWidth;
                tiBoxPosition["height_scale"] = tiBoxPosition.height / ptHeight;
                p["contents"] = { position: tiBoxPosition, branch: null };
                if((!("chai" in this.myChunk) || this.myChunk.chai == null) && ("editor" in this.$refs) && (this.$refs.editor != null)){
                    p["titleHeight"] = this.$refs.editor.$el.clientHeight/dpi;
                    p["title"] = this.editorData;
                }
                if(this.tiType==1 || this.tiType==2 || this.tiType==11){
                    let answerBoxs = this.$refs.answerBox;
                    let tiList = [];
                    p["index"] = (("chai" in this.myChunk) && this.myChunk.chai > 0)?this.myChunk.chai:this.myChunk.ixStart;;
                    for (let i=0;i<answerBoxs.length; i++){
                        let aw = this.anwList[i];
                        let a = {position: {left: aw.left, width: aw.width, top: aw.top, height: (answerBoxs[i].$el.clientHeight)/dpi}};
                        a = Object.assign(a, answerBoxs[i].callJson(config, pageConfig, tiBoxPosition));
                        tiList.push(a);
                        optionCount = aw.anwCount;
                        for (let j=0; j<a.ixList.length;j++) ixList.push(a.ixList[j]);
                        for (let j=0; j<a.numList.length;j++) numList.push(a.numList[j]);
                        console.log("for chunk ", a);
                    }
                    p["startSeq"] = this.myChunk.startSeq;
                    p["contents"]["branch"] = tiList;
                }

                if(this.tiType == 3 || this.tiType == 4 || this.tiType == 5 || this.tiType == 6){
                    let tiIndex = (!("chai" in this.myChunk) || this.myChunk.chai == null)?this.tiIndex:this.tiIndex-1;
                    let tiKey = this.myChunk.ixStart;
                    if(this.tiType == 3 && ("chai" in this.myChunk) && this.myChunk.chai>0){
                        tiKey = this.myChunk.chai;
                    }
                    let tiCount = this.myChunk.count;
                    p["startSeq"] = this.myChunk.startSeq;
                    if(this.tiType == 4 || this.tiType == 5 || this.tiType == 6){
                        tiCount = 1; numList = [1]; ixList = [tiKey];
                    }else{
                        let startSeq = this.myChunk.startSeq?this.myChunk.startSeq:1;
                        for (let i=0; i<tiCount; i++){
                            numList.push(startSeq+i); ixList.push(tiKey+i);
                        }
                    }
                    if(this.tiType == 3 ){
                        let fillBank = this.myChunk.fillBlank;
                        if("allFillBlank" in this.myChunk) fillBank = this.myChunk.allFillBlank;
                        p["contents"]["content"] = fillBank;
                        p["anwCount"] = this.myChunk.anwCount;
                        p["count"] = this.myChunk.allCount;
                    }
                    if(this.tiType == 4){
                        p["answerChildList"] = this.myChunk.answerChildList;
                    }
                    if(this.tiType == 5 ){
                        p["compositionCount"] = this.myChunk.allCount;
                    }
                    if(this.tiType == 6){
                        p["englishChildList"] = this.myChunk.englishChildList;
                    }
                    console.log("chunk tiIndex", this.showTitle, tiIndex, tiKey);
                    if(!("chai" in this.myChunk) || this.myChunk.chai == null){ this.setTiIndex(this.tiIndex+numList.length); }
                    p["contents"]["branch"] = [{numList: numList, ixList: ixList}];
                    p["index"] = tiKey;
                }
                if(this.tiType == 9){
                    p["height9"] = this.myChunk.height9;
                }
                let obj = {topicDesc: {topicType: anwCardUtils.getTopicType(this.tiType) ,index: p['index'], optionCount: this.myChunk.anwCount, numList: numList, ixList: ixList}, p: p};
                console.log("chunk json", obj);
                return obj;
            },
        }


    }
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">

    .examChunk{
        position: relative;
        /*z-index: 99;*/

        .ti-title{
            margin: 5px 0px;
        }
        .ti-list{
            position: relative;
            display: block;
            border: 1px solid black;
            min-height: 20px;
            width: 100%;
            box-sizing: border-box;
            font-size: 12px;
            flex-direction: column;

        }
        .fill-blank, .answer-question{
            position: relative;
            display: block;
            border: 1px solid black;
            width: 100%;
            box-sizing: border-box;
            flex-direction: column;
            white-space:nowrap;
            word-wrap: unset;
            overflow: hidden;
        }
        .empty-block{
            margin-top: 16px;
            position: relative;
            display: block;
            border: 1px solid black;
            width: 100%;
        }
        .fill-blank-desc {
            text-align: left;
            padding: 5px;
            word-break: break-all;
            .preview{
                line-height: 30px;
                font-size: 12px;
                padding: 5px 10px;
                overflow: hidden;
                //padding: 5px 10px;
                //letter-spacing:1px;
                //word-spacing:0.5em;
                white-space:nowrap;
                word-wrap: unset;
            }
            ::v-deep .el-textarea__inner{
                font-size: 12px;
                padding: 5px 10px;
                //text-align: center;
                white-space:nowrap;
                overflow: hidden;
                color: black;
                border: none;
                line-height: 30px;
            }
            p {
                font-size: 12px;
                margin: 1mm 0;
            }
        }
        .composition {
            border: 1px solid #000;
            padding: 10px;
        }

    }
</style>

