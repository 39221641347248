<template>
    <div class="regNo" :style="{width: (total*itemWidth+(total-2))+'px'}">

        <div :style="{width: (total*itemWidth+(total-4))+'px'}" class="no1">
            <el-input v-model="regNoTitle" class="no-title" @blur="regNoBlur"></el-input>
<!--            <span>准考证号</span>-->
        </div>
        <div class="title">
            <span v-for="index of total" class="item" :style="getTitleStyle(index)"></span>
        </div>
        <div class="title no-row" v-for="n of 10" :style="n==10?{borderBottom: '1px solid black'}:{}">
            <span v-for="index of total" :ref="('no'+n)" class="item-no" :style="getItemNoStyle(index)">[{{n-1}}]</span>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "regNo",
    props: {
        total: {
            type: Number
        },
        title: {
            type: String
        },
    },
    data() {
        return {
            noList: [], itemWidth: 26, regNoTitle: '准考证号',
            tdStyle: { borderRight: '1px solid black' },
        }
    },
    watch: {
        title: {
            deep: true, immediate: true,
            handler(n, o) {
                if (n) {
                    this.regNoTitle = n;
                }
            }
        },
    },
    mounted() {

    },
    methods:{
        ...mapActions('card',['setTiSelect', 'setTiChange', 'setIsCanSave']),
        callJson(config, pageConfig, header, regNoConfig){
            console.log("pageHeader regNo", config, pageConfig, header, regNoConfig);
            let {dpi} = config;
            let {headerTop} = header;
            let {pageLeft, pageTop, leftTop, ptWidth, ptHeight} = pageConfig;
            let {regNoLeft, regNoTop} = regNoConfig;
            let noList = [];
            let posTop = 0, posLeft = 0, posWidth = 0, posHeight = 0;
            let first = this.$refs['no1'][0];
            let posW = first.clientWidth*this.$refs['no1'].length;
            let posH = first.clientHeight*10;
            for (let i=1; i<11; i++){
                let arr = [];
                let nos = this.$refs['no'+i];
                for (let j = 0; j < nos.length; j++){
                    let {clientWidth, clientHeight, offsetLeft, offsetTop} = nos[j];
                    let tWidth = clientWidth / dpi, tHeight = clientHeight / dpi, tLeft = (pageLeft +offsetLeft) / dpi, tTop = (pageTop +offsetTop) / dpi;
                    if(i==1 && j==0) {posTop = regNoTop+(offsetTop)/dpi+15-12; posLeft = (offsetLeft)/dpi+15-6; posWidth = tWidth; posHeight = tHeight;  }
                    // if(i==10 && j==nos.length-1) {posWidth = tWidth; posHeight = tHeight; }
                    let pcLeft = j/nos.length; //以左上角黑点为参照物，占页面的百分比
                    let pcTop = (i-1)/10;
                    let pcWidth = clientWidth/posW;
                    let pcHeight = clientHeight/posH;
                    //width: tWidth, height: tHeight, left: tLeft, top: tTop,
                    arr.push({pcLeft: pcLeft, pcTop: pcTop, pcWidth: pcWidth, pcHeight: pcHeight });
                }
                noList.push(arr);
            }
            posWidth = posW/dpi+1; posHeight = posHeight*10;
            let h = {enabled: true, count: this.total, title: this.regNoTitle,
                position: { "top": posTop, "left": posLeft, "width": posWidth, "height": posHeight, left_begin: posLeft/ptWidth,
                    top_begin: posTop/ptHeight, left_end: (posLeft+posWidth)/ptWidth, top_end: (posTop+posHeight)/ptWidth,
                    width_scale: posWidth/ptWidth, height_scale: posHeight/ptHeight}, regNo: noList, regNoTitle: this.regNoTitle,
                examNumberMode: 0}
            console.log("callJson regNo", h, posW, posWidth, posHeight);
            return h;

        },
        regNoBlur(){
            this.setTiChange({id: this.id, regNoTitle: this.regNoTitle});
            this.setIsCanSave(true);

        },
        getTitleStyle(i){
            if(i==this.total) return {};
            return { borderRight: '1px solid black'};
        },
        getItemNoStyle(i){
            if(i==this.total) return {};
            return { borderRight: '1px solid black'};
        },
    }
}
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">
    .regNo{
        //border: 1px solid black;
        .no1{
            border: 1px solid black;
            .no-title{
                ::v-deep .el-input__inner{
                    font-size: 14px;
                    text-align: center;
                    color: black;
                }
            }
        }
        .title {
            display: flex;
            flex: none;
            justify-content: space-around;
            box-sizing: border-box;
            border-bottom: 1px solid black;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }
        .no-row {
            border-bottom: none;
        }

        .table {
        }

        .item{
            float: left;
            width: 26px;
            height: 25px;
        }

        .item-no{
            float: left;
            width: 26px;
            height: 22px;
            line-height: 22px;
            font-size: 12px;
        }
    }

</style>
