<template>
    <div class="answerBox" ref="answerBox" :style="{width: colWidth+'mm', left: left+'mm', top: top+'mm'}">
        <chooseOne ref="chooseOne" v-for="n of (end-start)" :type="type" :seq="(n+start-1)" :limit="limit"></chooseOne>
    </div>
</template>

<script>

import chooseOne from "./chooseOne";
import {getTopicType} from '../anwCardUtils'
import {mapActions, mapState} from "vuex";

export default {
    name: "answerBox",
    components: { chooseOne },
    computed: {
        ...mapState({
            tiIndex: state => state.card.tiIndex,
        }),
    },
    props: {
        type: {
            type: Number
        },
        limit: {
            type: Number
        },
        start: {
            type: Number
        },
        end: {
            type: Number
        },
        left: {
            type: Number
        },
        top: {
            type: Number
        },
        colWidth: {
            type: Number
        },
    },
    methods: {
        ...mapActions('card',['setTiIndex']),
        callJson(config, pageConfig, tiBox){
            let {dpi} = config;
            let {pageLeft, pageTop, leftTop, ptWidth, ptHeight, pageIndex} = pageConfig;
            let {clientWidth, clientHeight, offsetLeft, offsetTop} = this.$refs.answerBox;
            let chooseOne = this.$refs.chooseOne;
            let position = {left: this.left, top: this.top, width: this.colWidth, height: clientHeight/dpi};
            position["left_begin"] = position.left/ptWidth;
            position["top_begin"] = position.top/ptHeight;
            position["width_scale"] = position.width/ptWidth;
            position["height_scale"] = position.height/ptHeight;
            let numList = [], ixList = [], anwChooses = chooseOne[0].getChooseList();
            for (let i=this.start; i<this.end; i++) { numList.push(i); ixList.push(i)};
            let lst = [];
            let abox = {aboxWidth: clientWidth, aboxHeight: clientHeight, aboxLeft: offsetLeft, aboxTop: offsetTop};
            for (let i=0; i<chooseOne.length; i++){
                let c = chooseOne[i].callJson(config, pageConfig, tiBox, abox);
                lst.push(c);
            }
            console.log("answerbox tiIndex", this.tiIndex, ixList);
            let a = {chooseCount: this.limit, subAreaTopicType: getTopicType(this.type), position: position, numList: numList, ixList: ixList, chooses: anwChooses, chseList: lst };
            this.setTiIndex(this.tiIndex+ixList.length);
            return a;
        }
    }
}
</script>

<style scoped lang="scss" type="text/scss" rel="stylesheet/scss">

.answerBox{
    position: absolute;
    flex-direction: column;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    justify-content: center;
    flex: none;
    padding: 2mm 0px;

}
</style>
