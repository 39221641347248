<template>
    <div class="tiSettingDialog">
        <el-dialog :title="myTitle" width="30%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="tiSettingForm" ref="tiSettingDialogForm" label-suffix=":">

                <el-form-item label="标题"  prop="name" v-if="tiSettingForm.tiType!=9" :label-width="formLabelWidth">
                    <el-input v-model="tiSettingForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="题数量" v-if="tiSettingForm.tiType < 5 || tiSettingForm.tiType==11" prop="tiCount" :label-width="formLabelWidth">
                    <el-input-number :min="1"  v-model="tiSettingForm.tiCount"></el-input-number>
                </el-form-item>
                <el-form-item label="每列行数" v-if="tiSettingForm.tiType < 3 || tiSettingForm.tiType==11"  prop="tiRow" :label-width="formLabelWidth">
                    <el-input-number :min="1" :max="6"  v-model="tiSettingForm.tiRow"></el-input-number>
                </el-form-item>
                <el-form-item label="每行题数" v-if="tiSettingForm.tiType == 3"  prop="anwCount" :label-width="formLabelWidth">
                    <el-input-number :min="1" :max="4"  v-model="tiSettingForm.anwCount"></el-input-number>
                </el-form-item>
                <el-form-item label="选项数"  v-if="tiSettingForm.tiType < 3" prop="anwCount" :label-width="formLabelWidth">
                    <el-input-number :min="1" :max="12"  v-model="tiSettingForm.anwCount"></el-input-number>
                </el-form-item>
                <el-form-item label="字数" v-if="tiSettingForm.tiType == 5"  prop="tiCount" :label-width="formLabelWidth">
                    <el-input-number :min="1"  v-model="tiSettingForm.tiCount"></el-input-number>
                </el-form-item>
                <el-form-item label="开始序号" v-if="tiSettingForm.tiType < 5 || tiSettingForm.tiType==11"  prop="startSeq" :label-width="formLabelWidth">
                    <el-input-number :min="1" :max="300"  v-model="tiSettingForm.startSeq"></el-input-number>
                </el-form-item>
<!--                <el-form-item label="每题分数"  prop="tiRow" :label-width="formLabelWidth">-->
<!--                    <el-input-number  :precision="1" :step="0.5" v-model="tiSettingForm.score"></el-input-number>-->
<!--                </el-form-item>-->
                <el-form-item label="高度(毫米)" v-if="tiSettingForm.tiType==9" :label-width="formLabelWidth">
                    <el-input-number :min="1" :max="300"  v-model="tiSettingForm.height9"></el-input-number>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import * as anwCardUtils from './anwCardUtils'
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'tiSettingDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '设置数量分数',
                dialogVisible:false, loading: false,
                tiSettingForm:{ id: null, name: null, tiType: null, tiCount: null, tiRow: null, anwCount: null, startSeq: null, height9: null, score: null, },
                tiSettingRules: {
                    name: [{ required: true, message: '请输入标题', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            tiSetting: {
                type: Object
            }
        },
        watch: {
            tiSetting: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        let tiType =  n.tiType, tiTitle = anwCardUtils.getTiTitle(tiType);
                        this.tiSettingForm = { id: n.id, name: tiTitle, tiType: tiType, tiCount: n.tiCount, tiRow: n.tiRow, anwCount: n.anwCount, startSeq: n.startSeq, height9: n.height9, score: n.score,  };
                        // if(tiType ==11) this.tiSettingForm.anwCount = 2;
                        this.myTitle = tiTitle + "设置";
                    }else{
                        this.tiSettingForm = { id: null, name: null, tiType: null, tiCount: null, tiRow: null, anwCount: null, startSeq: null, height9: null, score: null, };
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){
                this.dialogVisible = false;
                this.$emit('ok', this.tiSettingForm);

            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .tiSettingDialog{

    }


</style>


