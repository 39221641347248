<template>
    <div class="posPoint" :style="myPos">
    </div>
</template>

<script>
    export default {
        name: "posPoint",
        props: {
            top: {
                type: Number
            },
            left: {
                type: Number
            }
        },
        data() {
            return {}
        },
        computed: {
            myPos(){
                return {
                    left: this.left+'mm', top: this.top+'mm'
                }
            }
        }
    }
</script>

<style scoped>
    .posPoint{
        background-color: black;
        position: absolute;
        width: 5mm;
        height: 5mm;
    }
</style>
